import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from '../../job.service';

@Component({
  selector: 'app-app-browsing-history',
  templateUrl: './app-browsing-history.component.html',
  styleUrls: ['./app-browsing-history.component.css']
})
export class AppBrowsingHistoryComponent implements OnInit {
  browsing_history:any;
 

 
    constructor(private router: Router, private _snackBar: MatSnackBar, private modalService: NgbModal, private jobService: JobService, private formBuilder: FormBuilder) { }
 


  ngOnInit(): void {

    this.jobService.customer_browsing_history()
    .subscribe(
      data => {
        console.log("data",data);
        this.browsing_history = data;
      });


      this.jobService.active_customer_in_app()
      .subscribe(
        data => {
          console.log("data",data);
        //  this.browsing_history = data;
        });


}


 

}
