<div class="form-group">
      <label for="cover">Upload Image</label>
      <ngx-dropzone (change)="onSelect($event,'cover_image')">
    <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"  [removable]="true" (removed)="onRemove(f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</ngx-dropzone>
    </div>
       
