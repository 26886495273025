<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="addForm" (ngSubmit)="submit()">
      <div>




        <h3>Add Category</h3>

        <div class="form-group">
          <label for="parent_category">Parent Category</label>
          <input rows="3" cols="4" formControlName="parent_category" placeholder="Parent Category"
            name="parent_category" class="form-control" id="parent_category">
        </div>

        <div class="form-group">
          <label for="name">Name</label>
          <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
        </div>

        <div class="form-group">
          <label for="cover">Cover</label>
          <input formControlName="cover" placeholder="Cover" name="cover" class="form-control" id="cover">
        </div>




        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editForm" (ngSubmit)="updatesubmit()">
      <div>
        <h3>Edit Category</h3>
        <div class="form-group">
          <label for="name">Name</label>
          <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
        </div>

        <figure class="figure" style="padding:30px;">
          <img src="{{editForm.value.cover}}" class="img-thumbnail" width="200px" height="200px">

        </figure>


        <div class="form-group">
          <label for="cover">Upload Image</label>
          <ngx-dropzone (change)="onSelect($event,'cover_image')">
            <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </div>


        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>



 
      <h3>Category List</h3> 


    

      <div class="table-responsive">
        <button (click)="add(content)" class="btn btn-primary btn-large">Add Category</button>

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Category Id </th>
              <th>Name</th>
              <th>Cover</th>




              <th>Action </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tbody>
            <tr *ngFor="let t of category_list index as i;">

              <td>{{t.id}}</td>
              <td>{{t.name}}</td>
              <td>




                <img alt="item" class="img-thumbnail saksh_thumb" [src]="t.cover" />
              </td>






              <td> <button (click)="edit(content1,t.id)" class="btn btn-primary btn-sm">Edit</button></td>
              <td> <button (click)="delete(t.id)" class="btn btn-danger btn-sm">Delete</button></td>
            </tr>
          </tbody>
        </table>

      </div> 