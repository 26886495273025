<div class="site">

  <div class="row">
    <div class="col-md-12">

      <h3> Customer browsing history</h3><br>
      <div class="table-responsive">


        <table class="table">



          <tr *ngFor="let t of browsing_history index as i;">


            <td>{{t.id}} </td>
            <td>{{t.name}} </td>
            <td> {{t.email}}</td>
            <td> {{t.mobile}}</td>
            <td> {{t.sell_price}}</td>
            <td>{{t.created_at}} </td>

          </tr>


        </table>


      </div>


    </div>
  </div>
</div>