<div class="row">

  <div class="col-md-6">


    <h1>Order History</h1>
    <form class="form-inline" [formGroup]="searchForm" (ngSubmit)="search()">





      <div class="form-group mx-sm-3 mb-2">
        <input type="date" class="form-control" id="date_search" placeholder="Date" formControlName="date_search">
      </div>
      <button type="submit" class="btn btn-secondary btn-sm ">Search</button>

    </form>

    <form class="form-inline" [formGroup]="ordersearchForm" (ngSubmit)="ordersearch()">
      <div class="form-group mx-sm-3 mb-2">
        <input type="text" width=10 class="form-control" id="order_id" placeholder="Order Id  "
          formControlName="order_id">
      </div>
      <button type="submit" class="btn btn-secondary btn-sm ">Search Order Id</button>


    </form>

  </div>
  <div class="col-md-6">




    <p> Pending :: Customer booked order

      <br />
      On-hold:: After review complete we asked buyer to make payment and buyer is taking time

      <br />
      Processing :: After payment received we ship the order
      <br />
      Completed :: After ship customer received the order



    </p>
  </div>
</div>




<button (click)="add()" class="btn btn-secondary btn-sm">Add Order</button>
<mat-tab-group>
  <mat-tab label="Pending">
    <div class="table-responsive">

      <table class="table table-striped table-bordered table-sm">

        <thead>
          <tr>

            <td>Order id</td>

            <td> Product </td>
            <td>Customer </td>

          </tr>


        </thead>


        <tbody>
          <tr *ngFor="let t of orders_history index as i;">






            <td *ngIf="t.order_status     ==   'pending'" (click)="sendto(t.order_id)">{{t.order_id}} <br />
              {{t.order_date}} </td>




            <td *ngIf="t.order_status     ==   'pending'" (click)="sendto(t.order_id)">



              <table class="table table-striped table-bordered table-sm">

                <thead>
                  <tr>

                    <td> Id</td>

                    <td>Name</td>
                    <td>Price</td>
                    <td>Quantity</td>


                  </tr>


                </thead>
                <tbody>
                  <tr *ngFor="let product of t.orders_products  ;">
                    <td>
                      {{product.id}}
                    </td>

                    <td>
                      {{product.name}}
                    </td>
                    <td>
                      {{product.selling_price}}
                    </td>
                    <td>
                      {{product.quantity}}
                    </td>
                  </tr>
                </tbody>
              </table>


            </td>
            <td *ngIf="t.order_status     ==   'pending'" (click)="sendto(t.order_id)">

              {{t.name}} <br />{{t.mobile_number}}<br /> {{t.email}}
              <br /><br />

              name {{t.address.name}}<br />
              mobile number {{t.address.mobile_number}}<br />
              <br />
              address {{t.address.address}}<br />
              city {{t.address.city}}<br />
              pincode {{t.address.pincode}}


            </td>






          </tr>
        </tbody>
      </table>

    </div>
  </mat-tab>









  <mat-tab label="On-hold">
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-sm">

        <thead>
          <tr>

            <td>Order id</td>

            <td>created_at</td>
            <td> Product </td>
            <td>Customer </td>
          </tr>


        </thead>



        <tbody>
          <tr *ngFor="let t of orders_history index as i;">






            <td *ngIf="t.order_status     ==   'On-hold'" (click)="sendto(t.order_id)">{{t.order_id}} <br />
              {{t.order_date}}</td>


            <td *ngIf="t.order_status     ==   'On-hold'" (click)="sendto(t.order_id)">



              <table class="table table-striped table-bordered table-sm">

                <thead>
                  <tr>

                    <td> Id</td>

                    <td>Name</td>
                    <td>Price</td>
                    <td>Quantity</td>


                  </tr>


                </thead>
                <tbody>
                  <tr *ngFor="let product of t.orders_products  ;">
                    <td>
                      {{product.id}}
                    </td>

                    <td>
                      {{product.name}}
                    </td>
                    <td>
                      {{product.selling_price}}
                    </td>
                    <td>
                      {{product.quantity}}
                    </td>
                  </tr>
                </tbody>
              </table>


            </td>
            <td *ngIf="t.order_status     ==   'On-hold'" (click)="sendto(t.order_id)">
              {{t.name}} <br />{{t.mobile_number}} <br /> {{t.email}}
              <br /><br />

              name {{t.address.name}}<br />
              mobile number {{t.address.mobile_number}}<br />

              address {{t.address.address}}<br />
              city {{t.address.city}}<br />
              pincode {{t.address.pincode}}


            </td>





          </tr>
        </tbody>


      </table>

    </div>
  </mat-tab>




  <mat-tab label="Processing">
    <div class="table-responsive">

      <table class="table table-striped table-bordered table-sm">
        <thead>
          <tr>
            <td>Order id</td>

            <td>created_at</td>
            <td> Product </td>
            <td>Customer </td>
          </tr>


        </thead>



        <tbody>
          <tr *ngFor="let t of orders_history index as i;">






            <td *ngIf="t.order_status     ==   'Processing'" (click)="sendto(t.order_id)">{{t.order_id}} <br />
              {{t.order_date}}
            </td>




            <td *ngIf="t.order_status     ==   'Processing'" (click)="sendto(t.order_id)">


              <table class="table table-striped table-bordered table-sm">

                <thead>
                  <tr>

                    <td> Id</td>

                    <td>Name</td>
                    <td>Price</td>
                    <td>Quantity</td>


                  </tr>


                </thead>
                <tbody>
                  <tr *ngFor="let product of t.orders_products  ;">
                    <td>
                      {{product.id}}
                    </td>

                    <td>
                      {{product.name}}
                    </td>
                    <td>
                      {{product.selling_price}}
                    </td>
                    <td>
                      {{product.quantity}}
                    </td>
                  </tr>
                </tbody>
              </table>



            </td>

            <td *ngIf="t.order_status     ==   'Processing'" (click)="sendto(t.order_id)">{{t.name}} <br />
              {{t.mobile_number}}<br />
              {{t.email}}
              <br /><br />

              name {{t.address.name}}<br />
              mobile number {{t.address.mobile_number}}<br /><br />

              address {{t.address.address}}<br />
              city {{t.address.city}}<br />
              pincode {{t.address.pincode}}
            </td>




          </tr>
        </tbody>


      </table>

    </div>
  </mat-tab>




  <mat-tab label="Completed">
    <div class="table-responsive">

      <table class="table table-striped table-bordered table-sm">

        <thead>
          <tr>
            <td>Order id</td>
            <td> Product </td>
            <td>Customer </td>
          </tr>


        </thead>



        <tbody>
          <tr *ngFor="let t of orders_history index as i;">






            <td *ngIf="t.order_status     ==   'Completed'" (click)="sendto(t.order_id)">{{t.order_id}} <br />
              {{t.order_date}}</td>



            <td *ngIf="t.order_status     ==   'Completed'" (click)="sendto(t.order_id)">


              <table class="table table-striped table-bordered table-sm">

                <thead>
                  <tr>

                    <td> Id</td>

                    <td>Name</td>
                    <td>Price</td>
                    <td>Quantity</td>


                  </tr>


                </thead>
                <tbody>
                  <tr *ngFor="let product of t.orders_products  ;">
                    <td>
                      {{product.id}}
                    </td>

                    <td>
                      {{product.name}}
                    </td>
                    <td>
                      {{product.selling_price}}
                    </td>
                    <td>
                      {{product.quantity}}
                    </td>
                  </tr>
                </tbody>
              </table>



            </td>


            <td *ngIf="t.order_status     ==   'Completed'" (click)="sendto(t.order_id)">

              {{t.name}} <br />
              {{t.mobile_number}} <br />
              {{t.email}}
              <br /><br />

              {{t.address.name}}<br />
              {{t.address.mobile_number}}<br />
              <br />
              {{t.address.address}}<br />
              {{t.address.city}}<br />
              {{t.address.pincode}}
            </td>


          </tr>
        </tbody>


      </table>

    </div>
  </mat-tab>
  <mat-tab label="All">
    <div class="table-responsive">

      <table class="table table-striped table-bordered table-sm">

        <thead>
          <tr>
            <td>Order id</td>

            <td>Status</td>
            <td> Product </td>
            <td>Customer </td>
          </tr>


        </thead>


        <tbody>
          <tr *ngFor="let t of orders_history index as i;">

            <td (click)="sendto(t.order_id)">{{t.order_id}} <br /> {{t.created_at}}</td>




            <td (click)="sendto(t.order_id)">{{t.order_status}}</td>
            <td (click)="sendto(t.order_id)">



              <table class="table table-striped table-bordered table-sm">

                <thead>
                  <tr>

                    <td> Id</td>

                    <td>Name</td>
                    <td>Price</td>
                    <td>Quantity</td>


                  </tr>


                </thead>
                <tbody>
                  <tr *ngFor="let product of t.orders_products  ;">
                    <td>
                      {{product.id}}
                    </td>

                    <td>
                      {{product.name}}
                    </td>
                    <td>
                      {{product.selling_price}}
                    </td>
                    <td>
                      {{product.quantity}}
                    </td>
                  </tr>
                </tbody>
              </table>



            </td>

            <td (click)="sendto(t.order_id)">{{t.name}} {{t.mobile_number}} {{t.email}}
              <br />

              {{t.address.name}}<br />
              {{t.address.mobile_number}}<br />

              {{t.address.address}}<br />
              {{t.address.mobile_number}}<br />
              {{t.address.pincode}}

            </td>



          </tr>
        </tbody>
      </table>

    </div>
  </mat-tab>
</mat-tab-group>