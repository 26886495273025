   <div class="site">

  <div class="row">
     <div class="col-md-12">

   <div class="table-responsive">

                <table class="table   ">
                  
     
         
                  

 <thead>
                    <tr>
                      <th>Id </th>
                      <th>Message</th>


                      <th>Date </th>


                 

                    </tr>
                  </thead>
<tbody>
  <tr *ngFor="let t of notification"  >

                    <td  >  {{t.id}} </td>
                    <td  >  {{t.message}} </td>
                    <td  >  {{t.created_at}} </td>
                    
                    </tr>
</tbody>

                </table>

            </div>


        </div></div></div>