<ng-template #content4 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editaddressForm" (ngSubmit)="updateaddressSubmit()">
      <div class="form-group">
        <label for="name">Name</label>
        <input formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
      </div>

      <div class="form-group">
        <label for="description">Mobile Number</label>
        <input formControlName="mobile_number" placeholder="Mobile Number" name="mobile_number" class="form-control"
          id="mobile_number">
      </div>


      <div class="form-group">
        <label for="city">City</label>
        <input formControlName="city" placeholder="City" name="description" class="form-control" id="city">
      </div>

      <div class="form-group">
        <label for="pincode">Pincode</label>
        <input formControlName="pincode" placeholder="Pincode" name="pincode" class="form-control" id="pincode">
      </div>


      <div class="form-group">
        <label for="address">Address</label>
        <textarea rows="3" cols="4" formControlName="address" placeholder="Enter Address..." name="address"
          class="form-control" id="address"></textarea>
      </div>


      <div class="modal-footer">
        <button class="btn btn-outline-primary">Submit</button>

      </div>

    </form>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editproductForm" (ngSubmit)="updateproductSubmit()">
      <div class="form-group">
        <label for="selling_price">Selling Price</label>
        <input formControlName="selling_price" placeholder="Selling Price" name="selling_price" class="form-control"
          id="selling_price">
      </div>

      <div class="form-group">
        <label for="quanity">Quantity</label>
        <input type="number" formControlName="quantity" placeholder="quantity" name="quantity" class="form-control"
          id="quantity">
      </div>

      <input type="hidden" formControlName="product_id" name="product_id" id="product_id">


      <input type="hidden" formControlName="order_id" name="order_id" id="order_id">


      <div class="modal-footer">
        <button class="btn btn-outline-primary">Submit</button>

      </div>

    </form>
  </div>
</ng-template>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Product List</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-row">



      <table class="table modeltitle">


        <tr *ngFor="let m of product_listshow index as i;">

          <td>
            {{m.name}}

          </td>
          <td>
            {{m.sell_price}} INR

          </td>
          <td>
            <button (click)="onproductSubmit2(m.id)" class="btn btn-outline-primary btn-sm">Select</button>

          </td>

        </tr>
      </table>



    </div>

  </div>
</ng-template>


<div class="row" *ngIf="loadpage">
  <div class="col-md-10">

    <h1>
      #{{order_id}} {{product_name}}
    </h1>
    Status {{order_status}}
    created {{created_at}}
  </div>

  <div class="col-md-2 text-right">

    <button class="btn btn-primary   float-end" [matMenuTriggerFor]="menu">Action menu</button>
    <mat-menu #menu="matMenu">


      <button (click)="order_status_update(order_id,'Processing')" mat-menu-item>Processing</button>


      <button (click)="order_status_update(order_id,'Completed')" mat-menu-item>Completed</button>

      <button (click)="order_status_update(order_id,'Refunded')" mat-menu-item>Refunded</button>
      <button (click)="order_status_update(order_id,'Cancelled')" mat-menu-item>Cancelled</button>

      <button (click)="order_status_update(order_id,'On-hold')" mat-menu-item>On-hold</button>
    </mat-menu>

  </div>
</div>



<mat-tab-group *ngIf="loadpage">





  <mat-tab label="Product Details">




    <mat-accordion>





      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
        *ngFor="let t of product_list index as i;">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Product # {{t.id}} - {{t.name}}


          </mat-panel-title>

        </mat-expansion-panel-header>

        <!--  <div class="float-right">

    <mat-icon (click)="open3(content3,t.id)">edit</mat-icon>
     <mat-icon (click)="delete_part_product(t.id)">delete</mat-icon>
</div> -->
        <div class="float-right">


          <mat-icon (click)="open1(content1,t.id,t.selling_price,t.quantity)">edit</mat-icon>
          <mat-icon (click)="delete_product(t.id)">delete</mat-icon>

        </div>

        <table class="table  text-white">
          <tr>
            <td>
              Decription
            </td>
            <td>Selling Price

            </td>
            <td>Quantity
            </td>
          </tr>

          <tr>
            <td>
              {{t.name}}
            </td>
            <td> {{t.selling_price}}
            </td>

            <td> {{t.quantity}}
            </td>

          </tr>




        </table>
      </mat-expansion-panel>




    </mat-accordion>

    <button (click)="open(content)" mat-raised-button color="primary"> <mat-icon>add</mat-icon> Add Product</button>



  </mat-tab>



  <mat-tab label=" Customer Details">

    <mat-accordion>

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

          <mat-panel-title>
            Adddress
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="float-right">

          <mat-icon (click)="open4(content4,address_id)">edit</mat-icon>

        </div>


        Name

        {{name}}

        <br />
        Mobile Number

        {{mobile_number}}

        <br />

        City


        {{city}}

        <br />
        Pincode
        {{pincode}}


        <br />

        Address
        {{address}}





      </mat-expansion-panel>


    </mat-accordion>

  </mat-tab>

  <mat-tab label="Payment Details">
    <mat-accordion>


      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Payment Details
          </mat-panel-title>

        </mat-expansion-panel-header>
        <br>
        <table class="table  text-white">

          <tr>
            <td>
              Payment Method
            </td>
            <td> {{payment_method}}

            </td>
          </tr>


          <tr>
            <td>
              Payable Amount
            </td>
            <td> {{total}} INR

            </td>
          </tr>
        </table>


      </mat-expansion-panel>
    </mat-accordion>
  </mat-tab>





  <mat-tab label="Shipping Details">




    <mat-accordion>


      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Shipping Details
          </mat-panel-title>

        </mat-expansion-panel-header>


        <form [formGroup]="addshippingForm" (ngSubmit)="submitShipping()">



          <div class="form-group">
            <label for="descriptions"> Shipping Details </label>
            <textarea rows="3" cols="4" formControlName="shipping_details" placeholder="Shipping Details"
              name="shipping_details" class="form-control" id="shipping_details"></textarea>
          </div>




          <div class="modal-footer">
            <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>
          </div>



        </form>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

          <mat-panel-title>
            Shipping Details List
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="table-responsive">

          <table class="table table-striped">
            <thead>
              <tr>

                <th> Shipping Details </th>

                <th>Date </th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let t of order_shipping_list index as i;">


                <td>{{t.shipping_details}}</td>

                <td>{{t.created_at}}</td>

              </tr>
            </tbody>
          </table>
        </div>


      </mat-expansion-panel>


     
    </mat-accordion>
  </mat-tab>

  <mat-tab label="Order Note">


    <mat-accordion>


      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Order Note
          </mat-panel-title>

        </mat-expansion-panel-header>


        <form [formGroup]="addordernoteForm" (ngSubmit)="submitOrdernote()">



          <div class="form-group">
            <label for="descriptions"> Order Note</label>
            <textarea rows="3" cols="4" formControlName="order_note" placeholder="Order Note" name="order_note"
              class="form-control" id="order_note"></textarea>
          </div>




          <div class="modal-footer">
            <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>
          </div>



        </form>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

          <mat-panel-title>
            Order Note List
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="table-responsive">

          <table class="table table-striped">
            <thead>
              <tr>
                <th> ID </th>
                <th>Note</th>
                <th>Date </th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let t of order_note_list index as i;">

                <td>{{t.id}}</td>
                <td>{{t.order_note}}</td>


                <td>{{t.created_at}}</td>

              </tr>
            </tbody>
          </table>
        </div>


      </mat-expansion-panel>

    </mat-accordion>
  </mat-tab>



  
  <mat-tab label="Old Order history  ">

    
    <mat-accordion>

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

          <mat-panel-title>
            Order List
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="table-responsive">

          <table class="table table-striped  text-white">
            <thead>
              <tr>
                <th> ID </th>
                <th>Product</th>


                <th> Total </th>
                <th>Selling price </th>
                <th>created_at </th>
                 

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let t of customer_order_list index as i;">

                <td>{{t.id}}</td>
                <td>{{t.name}}</td>

                <td>{{t.quantity}}</td>
                <td>{{t.selling_price}}     </td>
                <td>{{t.created_at}}     </td>
 

              </tr>
            </tbody>
          </table>
        </div>


      </mat-expansion-panel>

      
    </mat-accordion>

  </mat-tab>



</mat-tab-group>



<div *ngIf="!loadpage">

  Loading...
</div>