

import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";

import { JobService } from '../job.service';
import { User } from 'src/app/models/user.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private router: Router, private jobService: JobService,
    private _snackBar: MatSnackBar) { }

  set_up_downForm: FormGroup;

  histories: any;
  app_status:any;
  notification:any;
 




  ngOnInit(): void {



    this.set_up_downForm = this.formBuilder.group({
      notification: [''],

      app_status: ['OFFLINE']

    });

    this.fetch_history();
  }

  fetch_history() {



 
      this.jobService.get_system_status()
        .subscribe(
          data => {

            this.app_status = data.app_status;

            this.notification = data.notification;


          });
   





    this.jobService.upline_histories()
      .subscribe(
        data => {

          this.histories = data;

        });
  }


  set_online() {

    this.set_up_downForm.controls['app_status'].setValue("ONLINE");

    this.jobService.set_up_down(this.set_up_downForm.value)
      .subscribe(data => {
        console.log(data);


        this._snackBar.open(data['message'], "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'
        });


        this.fetch_history();
      });


  }
  set_up_down() {

    this.set_up_downForm.controls['app_status'].setValue("OFFLINE");


    this.jobService.set_up_down(this.set_up_downForm.value)
      .subscribe(data => {
        console.log(data);


        this._snackBar.open(data['message'], "Close", {
          duration: 2000,

          verticalPosition: 'top',
          horizontalPosition: 'center'
        });


        this.fetch_history();
      });


  }

}  