

<div class="site">
 

<div class="row">
  <div class="col-md-12">
     <form [formGroup]="addForm" (ngSubmit)="onSubmit()"> 

     	  <h3>Add Address</h3>
 <div class="form-group">
      <label for="description">Name</label>
      <input rows="3" cols="4" formControlName="name"   placeholder="Name" name="name" class="form-control" id="name" >
    </div>

<div class="form-group">
      <label for="mobile_number">Mobile Number</label>
      <input rows="3" cols="4" formControlName="mobile_number"  placeholder="Mobile Number" name="mobile_number" class="form-control" id="mobile_number" >
    </div>
       

<div class="form-group">
      <label for="city">City</label>
      <input rows="3" cols="4" formControlName="city"  placeholder="City" name="city" class="form-control" id="city" >
    </div>

    <div class="form-group">
      <label for="pincode">Pincode</label>
      <input rows="3" cols="4" formControlName="pincode"  placeholder="Pincode" name="pincode" class="form-control" id="pincode" >
    </div>


    <div class="form-group">
      <label for="address">Address</label>
      <textarea rows="3" cols="4" formControlName="address"  placeholder="Enter Address..." name="address" class="form-control" id="address" ></textarea>
    </div>


  <div class="modal-footer">
    <button  class="btn btn-outline-primary btn-sm" >Submit</button>

  </div>

  </form>
</div></div></div>