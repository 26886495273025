<div class="site">
  <div class="row">
    <div class="col-md-12">
      <h3>Engineer List</h3><br>
      <div class=" float-right">
        <mat-icon (click)="openBottomSheet()">add_box</mat-icon>
      </div>
      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Engineer Id </th>
              <th>Name</th>
              <th>Mobile Number</th>

              <th>Date </th>


              <th>Action </th>

            </tr>
          </thead>
          <tbody>
            <tr>

              <td>1</td>
              <td>sddf</td>
              <td>8978686786</td>


              <td>10-06-22</td>

              <td> <button (click)="openBottomSheet()" class="btn btn-outline-primary btn-sm">Edit</button></td>

            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>