

<div class="container">
  <div class="row"><div class="col-md-4  "> </div>
    <div class="col-md-4  ">
      <div class="login-box">
        <h3 class="box-title">Register ...</h3>
        <hr>
        <form (ngSubmit)="reister()" #registerForm="ngForm">
          


          <div class="form-group">
        <label for="name">Name</label>
          <input type="text" class="form-control" id="name" required ngModel #name="ngModel" name="name" placeholder="Enter your registered Name ">
          <div *ngIf="name.invalid && name.touched" class="alert alert-danger">
            Please provide your name!
          </div>
      </div>


          <div class="form-group">
            <label>Email Address<sup class="text-danger">*</sup></label>
            <input type="email"  class="form-control" id="email" name="email" required email ngModel #email="ngModel"   placeholder="Enter your registered email address">
            <div *ngIf="email.invalid && email.touched" class="alert alert-danger">
          Please provide a correct email address!
        </div>
          </div>
          <div class="form-group">
            <label>Password<sup class="text-danger">*</sup></label>
            <input type="password" class="form-control" id="password" name="password" required minlength="4" ngModel #password="ngModel"  placeholder="Enter your password">
            <div *ngIf="password.invalid && password.touched" class="alert alert-danger">
          Password must have at least 4 characters
        </div>
          </div>
          <div class="form-group">
 


           
            <button type="submit" class="btn btn-secondary btn-sm  pull-right"  [disabled]="registerForm.invalid">Register</button>
               <a routerLink="/login">Login Here</a>
           
          </div>
        </form>
        
         
      </div>
    </div>
  </div>
</div>









<!--


<div class="container">
    <h1>Register</h1>
    <form (ngSubmit)="reister()" #registerForm="ngForm">
      <div class="form-group">
        <label for="name">Name</label>
          <input type="text" class="form-control" id="name" required ngModel #name="ngModel" name="name">
          <div *ngIf="name.invalid && name.touched" class="alert alert-danger">
            Please provide your name!
          </div>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="text" class="form-control" id="email" required email ngModel #email="ngModel" name="email">
        <div *ngIf="email.invalid && email.touched" class="alert alert-danger">
          Please provide a correct email address!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" 
          required
          ngModel
          #password="ngModel"
          name="password"
          minlength="4">
        <div *ngIf="password.invalid && password.touched" class="alert alert-danger">
          Password must have at least 4 characters!
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="registerForm.invalid">Register</button>
       <a routerLink="/login">Login Here</a>
    </form>
  </div>
  -->