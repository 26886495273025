

<div class="row">




   <div class="col-md-12">
    <div class="card" >


      <div class="card-body">  
  <h5 class="card-title">Confirm Payment</h5>
 <div class="form-group">

        <label for="currency">Id : {{id}}</label>
      </div>
 
  <div class="form-group">
      <label for="amount">Username: {{name}}</label>
      
    </div>

    
      
<div class="form-group">
      <label for="gender">Email : {{email}}</label>

    </div>
 
    
    <div class="form-group">
      <label for="amount">Amount: {{amount}}</label>
      
    </div>

    <div class="form-group">

        <label for="currency">Currency : {{coin}}</label>
      </div>
      
<div class="form-group">
      <label for="gender">Type : {{type}}</label>

    </div>
 

    <button class="btn btn-primary btn-sm" (click)="confirm()">Confirm</button>




  </div>

</div>
</div>


</div>