 


<div class="site">
  <div class="row">
    <div class="col-md-12">
      <h3>Product List</h3><br>


      <button (click)="add()" class="btn btn-primary btn-large">Add Product</button>


      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Product Id </th>
              <th>Name</th>
              <th>Wholesale price</th>
              <th>Wholesale min quantity</th>
              <th>Sell price</th>
              <th>Commission</th>
              <th>Date </th>
              <th>Action </th>
              <th></th> <th></th>
            </tr>
          </thead>
          <tbody>
          <tbody>

             


            <tr *ngFor="let t of products_list index as i;">

              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }"   >{{t.id}}</td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }" >{{t.name}}</td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }" >{{t.wholesale_price}}</td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }">{{t.wholesale_min_quantity}}</td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }">{{t.sell_price}}</td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }">{{t.commission}}</td>

              

              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }">{{t.created_at}}</td>

              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }" > 
                
                
                 <a *ngIf="t.status ===   'Unpublished'" (click)="publish_product(t.id)"  ><mat-icon>  publish</mat-icon></a> 
                <a *ngIf="t.status ===   'Published'" (click)="unpublish_product(t.id)"  > <mat-icon> unpublished</mat-icon></a></td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }"> <a (click)="edit(t.id)"  ><mat-icon>  edit</mat-icon></a></td>
              <td  [ngClass]="{'table-warning': t.status ===   'Unpublished' }"> <a (click)="delete(t.id)"  ><mat-icon>  delete</mat-icon></a></td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>