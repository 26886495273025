import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  

  private readonly baseurl = 'https://nbsadmin23.nbscomputer.com/api/'; 
  
 // private readonly loginUrl1 = 'https://nbsadmin23.nbscomputer.com/api/auth/googleauthverify';
  //private readonly registerUrl1 = 'https://nbsadmin23.nbscomputer.com/api/auth/register';
  //private readonly loginUrl2 = 'https://nbsadmin23.nbscomputer.com/api/auth/login';
  
 


  constructor(
    private http : HttpClient
  ) {  }

  register(body) {
    return this.http.post(this.baseurl+"register", body);
  }

  login(body) {
    return this.http.post(this.baseurl+"admin_login", body);
  }

login1(body) {
    return this.http.post(this.baseurl+"admin_login" , body);
  }


  checkgoogleauth(body) {
    return this.http.post(this.baseurl+"googleauthtest", body);
  }

sendmail(body){
return this.http.post(this.baseurl+"sendemail" , body);
  
}



  logout() {
    localStorage.clear();
  }

  isAuthenticated() {
    return localStorage.getItem('token') !== null;
    
  }

  isAdmin() {
  let roll = window.localStorage.getItem("roll");
  
    return parseInt(roll)== 10;
    
  }



  isUser() {
  let roll = window.localStorage.getItem("roll");
  
    return parseInt(roll)!== 10;
    
  }

  getToken() {
    let token = localStorage.getItem('token');



    return token;
  }
}