<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Engineer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="addForm" (ngSubmit)="submit()">
      <div>




        <h3>Add an Engineer</h3>
        <div class="form-group">
          <label for="name">Name</label>
          <input rows="3" cols="4" formControlName="engineer_name" placeholder="Name" name="engineer_name"
            class="form-control" id="engineer_name">
        </div>

        <div class="form-group">
          <label for="engineer_mobile_number">Mobile Number</label>
          <input formControlName="engineer_mobile_number" placeholder="Mobile Number" name="engineer_mobile_number"
            class="form-control" id="engineer_mobile_number">
        </div>

        <div class="form-group">
          <label for="customer_mobile_number">Upload Image</label>
          <ngx-dropzone (change)="onSelect($event,'engineer_image')">
            <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>



          </ngx-dropzone>
        </div>


        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Engineer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editForm" (ngSubmit)="updatesubmit()">
      <div>


        <h3>Edit an Engineer</h3>

        <!-- <img src="editForm.value.image"> -->
        <div class="form-group">
          <label for="name">Name</label>
          <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
        </div>

        <!--     <input type = "text" formControlName="image" placeholder="Upload Image" name="image" class="form-control" id="image" > -->

        <div class="form-group">
          <label for="mobile_number">Mobile Number</label>
          <input formControlName="mobile_number" placeholder="Mobile Number" name="mobile_number" class="form-control"
            id="mobile_number">
        </div>



        <figure class="figure" style="padding:30px;">
          <img src="{{editForm.value.image}}" class="img-thumbnail" width="200px" height="200px">

        </figure>


        <br>
        <ngx-dropzone (change)="onSelect($event,'engineer_image')">
          <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
            [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </ngx-dropzone>

        <!--  
<div class="form-group">
      <label for="image">Upload Image</label>
      <input type = "file" formControlName="image" placeholder="Upload Image" name="image" class="form-control" id="image" >
    </div> -->


        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>

<div class="site">
  <div class="row">
    <div class="col-md-12">
      <h3>Engineer List</h3><br>


      <button (click)="add(content)" class="btn btn-primary btn-large">Add an Engineer</button>


      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
            <tr>
              <th>Engineer Id </th>
              <th>Name</th>
              <th>Mobile Number</th>

               


              <th>Action </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tbody>
            <!-- (click)="job_list(t.id)" -->
            <tr *ngFor="let t of engineers_list index as i;">

              <td   (click)="sendto(t.id)">{{t.id}}</td>
              <td   (click)="sendto(t.id)">{{t.name}}</td>
              <td   (click)="sendto(t.id)">{{t.mobile_number}}</td>

 

              <td> <button (click)="edit(content1,t.id)" class="btn btn-primary btn-sm">Edit</button></td>
              <td> <button (click)="delete(t.id)" class="btn btn-danger btn-sm">Delete</button></td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>