<ng-template #content9 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assign Engineers</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addengineerForm" (ngSubmit)="engineersubmit()">

      <div class="form-group">
        <label for="description">Assign Engineer</label>
        <br>
        <span *ngFor="let t of engineers">
          <input type="radio" id="html" formControlName="engineer_id" name="engineer_id" value="{{t.id}}">
          <label for="html"> &nbsp; &nbsp;{{t.name}}</label><br>
        </span>

        <!-- <input type="radio" id="css" name="fav_language" value="CSS">
<label for="css"> CSS</label><br>
<input type="radio" id="javascript" name="fav_language" value="JavaScript">
<label for="javascript"> JavaScript</label>  -->
      </div>

      <div class="form-group">
        <label for="notes">Assign notes</label>
        <textarea rows="3" cols="4" formControlName="notes" placeholder="Assign notes..." name="notes"
          class="form-control" id="notes"></textarea>
      </div>
      <div class="modal-footer">
        <button type="submit" *ngIf="btnengineersubmit" class="btn btn-outline-primary">Assign</button>

      </div>

    </form>
  </div>
</ng-template>




<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Support Message</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="description">Support Message</label>
        <textarea rows="3" cols="4" placeholder="Enter Support Message..." name="description" class="form-control"
          id="description"></textarea>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary">Add Message</button>

      </div>

    </form>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="addaddressForm" (ngSubmit)="addaddressSubmit()">


      <div class="form-group">
        <label for="name">Name</label>
        <input formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
      </div>

      <div class="form-group">
        <label for="description">Mobile Number</label>
        <input formControlName="mobile_number" placeholder="Mobile Number" name="mobile_number" class="form-control"
          id="mobile_number">
      </div>


      <div class="form-group">
        <label for="city">City</label>
        <input formControlName="city" placeholder="City" name="description" class="form-control" id="city">
      </div>

      <div class="form-group">
        <label for="pincode">Pincode</label>
        <input formControlName="pincode" placeholder="Pincode" name="pincode" class="form-control" id="pincode">
      </div>


      <div class="form-group">
        <label for="address">Address</label>
        <textarea rows="3" cols="4" formControlName="address" placeholder="Enter Address..." name="address"
          class="form-control" id="address"></textarea>
      </div>

      <div class="modal-footer">
        <button class="btn btn-outline-primary">Submit</button>

      </div>

    </form>
  </div>
</ng-template>



<ng-template #content4 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editaddressForm" (ngSubmit)="updateaddressSubmit()">
      <div class="form-group">
        <label for="name">Name</label>
        <input formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
      </div>

      <div class="form-group">
        <label for="description">Mobile Number</label>
        <input formControlName="mobile_number" placeholder="Mobile Number" name="mobile_number" class="form-control"
          id="mobile_number">
      </div>


      <div class="form-group">
        <label for="city">City</label>
        <input formControlName="city" placeholder="City" name="description" class="form-control" id="city">
      </div>

      <div class="form-group">
        <label for="pincode">Pincode</label>
        <input formControlName="pincode" placeholder="Pincode" name="pincode" class="form-control" id="pincode">
      </div>


      <div class="form-group">
        <label for="address">Address</label>
        <textarea rows="3" cols="4" formControlName="address" placeholder="Enter Address..." name="address"
          class="form-control" id="address"></textarea>
      </div>


      <div class="modal-footer">
        <button class="btn btn-outline-primary">Submit</button>

      </div>

    </form>
  </div>
</ng-template>


<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Machine</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-machine [customer_id]="currentCustomer_id"></app-add-machine>

  </div>
</ng-template>



<ng-template #content6 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Machine</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-edit-machine [machine_id]="currentMachine_id"></app-edit-machine>

  </div>
</ng-template>


<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="addproductForm" (ngSubmit)="productSubmit()">

      <div class="form-group">
        <label for="product_name">Product Name</label>
        <input formControlName="product_name" placeholder="Product Name" name="product_name" class="form-control"
          id="product_name">
      </div>

      <div class="form-group">
        <label for="product_amount">Product Amount</label>
        <input formControlName="product_amount" placeholder="Amount" name="product_amount" class="form-control"
          id="product_amount">
      </div>
      <!-- 
      <div class=" float-right">
        <mat-icon (click)="add_product()">add_box</mat-icon>
      </div>
      <hr> -->
      <!-- {{arrayProduct}} -->
      <!--   <table class="table table-striped">
        <tbody>
          <tr *ngFor="let t of arrayProduct index as i;">

            <td>{{t.name}}</td>
            <td>{{t.amount | number}}</td>
            <td>
              <mat-icon (click)="remove_product(i )">delete</mat-icon>
            </td>
          </tr>
        </tbody>
      </table> -->


      <div class="modal-footer">
        <button type="submit" class="btn btn-outline-primary">Create Product</button>

      </div>

    </form>
  </div>
</ng-template>


<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Product</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editproductForm" (ngSubmit)="productUpdate()">




      <div class="form-group">
        <label for="product_name">Product Name</label>
        <input rows="3" cols="4" formControlName="product_name" placeholder="Product Name" name="product_name"
          class="form-control" id="product_name">
      </div>


      <div class="form-group">
        <label for="product_amount">Product Amount</label>
        <input rows="3" cols="4" formControlName="product_amount" placeholder="Amount" name="product_amount"
          class="form-control" id="product_amount">
      </div>

      <!--       <div class=" float-right">
        <mat-icon>add_box</mat-icon>
      </div>
 -->

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Submit Product</button>

      </div>

    </form>
  </div>
</ng-template>
 



  <div class="row">
    <div class="col-md-10">

      <h1>
       #{{job_work.id}} {{job_work.title}}
      </h1>
      <p> Status {{job_work.status}} , Asigned to : {{job_work.engineer_name}} dated
        {{job_work.assign_engineer_date}}</p>


      <p> {{job_work.job_work_note}} </p>


    </div>

    <div class="col-md-2 text-right">

      <button class="btn btn-secondary  btn-lg float-end" [matMenuTriggerFor]="menu">Action menu</button>
      <mat-menu #menu="matMenu">
        <a *ngIf="visible_generate_bar_code"
          href="https://nbsadmin.sakshstore.com/barcode/cv.php?job_work_id={{job_work.id}}" target="_blank">

          <button mat-menu-item>Generate BAR Code</button></a>

        <button mat-menu-item (click)="assign_engineer(content9,job_work.id)">Assign Engineer</button>

        <button *ngIf="visible_close_complain" mat-menu-item (click)="close_complain(job_work.id)">Close
          Complain</button>

        <button *ngIf="visible_incomplete_close" mat-menu-item (click)="inclose_complain(job_work.id)">Incomplete Close
          Complain</button>

        <button *ngIf="visible_hold_complain" mat-menu-item (click)="hold_complain(job_work.id)">Hold
          Complain</button>


       <button *ngIf="visible_deliver_machine" mat-menu-item
                    (click)="deliver_machine(job_work.id)">Deliver  Machine</button> 

        <button *ngIf="visible_deposit_machine" mat-menu-item (click)="deposit_machine(job_work.id)">Deposit
          Machine</button>

        <button *ngIf="visible_send_delivery_otp" (click)="send_delivery_otp(job_work.id)" mat-menu-item>Send
          Delivery OTP</button>

        <button *ngIf="visible_verify_delivery_otp" (click)="verify_delivery_otp(job_work.id)" mat-menu-item>
          Verify Delivery OTP</button>


        <button *ngIf="visible_reopen_complain" (click)="reopen_complain(job_work.id)" mat-menu-item>
          Reopen complain</button>


        <a href="{{print_job_work}}/{{job_work.id}}/{{hash}}" target="_blank">

          <button mat-menu-item>Print job work</button></a>


      </mat-menu>






    </div>
  </div>


  <mat-tab-group>

    <mat-tab label="Complain Details">




      <mat-accordion>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Problem Details
            </mat-panel-title>

          </mat-expansion-panel-header>
          <div class="text-justify">

            {{job_work.problem_details}}
          </div>
        </mat-expansion-panel>

      </mat-accordion>



    </mat-tab>
    <mat-tab label="Products">

      <button class="btn btn-primary btn-sm" (click)="open2(content2)">Add Product</button>


      <mat-accordion>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          *ngFor="let t of product_list index as i;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Product ID # {{t.id}}


            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="float-right">

            <mat-icon (click)="open3(content3,t.id)">edit</mat-icon>
            <mat-icon (click)="delete_part_product(t.id)">delete</mat-icon>
          </div>

          <br>
          <table class="table  text-white">

            <tr>

              <td>
                Decription
              </td>
              <td>Amount

              </td>
            </tr>




            <tr>


              <td>
                {{t.product_name}}


              </td>
              <td> {{t.product_amount}} INR

              </td>
            </tr>

            <tr>

              <td>
                Total
              </td>
              <td> {{t.product_amount}} INR

              </td>
            </tr>






          </table>
















        </mat-expansion-panel>

      </mat-accordion>


    </mat-tab>


    <mat-tab label=" Customer Details">




      <mat-accordion>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>

            <mat-panel-title>
              Adddresses
            </mat-panel-title>

          </mat-expansion-panel-header>




          <div class="row">


            <div class="col-md-3 " *ngFor="let add of customer_address_list index as i;">
              <mat-card class="  example-card">

                <mat-card-content>
                  <p>Mobile Number : {{add.mobile_number}} <br />
                    City : {{add.city}} <br />
                    Pincode: {{add.pincode}} <br />
                    Address: {{add.address}}</p>


                </mat-card-content>
                <mat-card-actions>
                  <mat-icon (click)="open4(content4,add.address_id)">edit</mat-icon> 
                  <mat-icon (click)="delete_address(add.address_id)">delete</mat-icon> 

                </mat-card-actions>
              </mat-card>

            </div>


            <div (click)="open1(content1)" class="col-md-2">
              <mat-card class="example-card">
                <mat-card-title> </mat-card-title>

                <mat-card-content>
                  <i class="fa fa-plus fa-6x" aria-hidden="true"></i>

                </mat-card-content>
                <mat-card-actions>


                </mat-card-actions>
              </mat-card>
            </div>



          </div>




        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Machine list
            </mat-panel-title>

          </mat-expansion-panel-header>




          <div class="row">
            <div class="col-md-3" *ngFor="let m of customer_machine_list index as i;">
              <mat-card class="example-card">
                <mat-card-title>{{m.title}}</mat-card-title>

                <mat-card-content>
                  <img src="{{m.image}}" style="width:100px; height:100px;">
                  <br />
                  Brand {{m.brand}} <br /> Model {{m.model}}


                </mat-card-content>
                <mat-card-actions>
                  <mat-icon (click)="open6(content6,m.id)">edit</mat-icon> Edit
                  <mat-icon (click)="delete_machine(m.id)">delete</mat-icon> Delete

                </mat-card-actions>
              </mat-card>
            </div>

            <div (click)="open5(content5)" class="col-md-1">
              <mat-card class="example-card">
                <mat-card-title> </mat-card-title>

                <mat-card-content>
                  <i class="fa fa-plus fa-6x" aria-hidden="true"></i>

                </mat-card-content>
                <mat-card-actions>


                </mat-card-actions>
              </mat-card>
            </div>

          </div>

        </mat-expansion-panel>
      </mat-accordion>

    </mat-tab>

    <mat-tab label="Customer History">
      <mat-accordion>


        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Customer History
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="table-responsive">

            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Job Work ID </th>
                  <th>Title</th>


                  <th>Date </th>



                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let t of customer_job_work_history index as i;" (click)="sendto(t.id)">

                  <td>{{t.id}}</td>
                  <td>{{t.title}}</td>



                  <td>{{t.created_at}}</td>



                </tr>
              </tbody>
            </table>
          </div>



        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <mat-tab label="Assign Engineer">


      <mat-accordion>


        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Assign Engineer
            </mat-panel-title>

          </mat-expansion-panel-header>





          <div class="row">
            <div class="col-md-2 " *ngFor="let t of assign_engineer_history index as i;">

              <mat-card>
                <mat-card-title>{{t.engineer_name}}</mat-card-title>

                <mat-card-content>
                  <img src="{{t.image}}" style="width:100px; height:100px;">
                  <br />
                  {{t.engineer_assign_notes}}
                  <br>
                  {{t.created_at}}

                </mat-card-content>

              </mat-card>



            </div>

          </div>




        </mat-expansion-panel>
      </mat-accordion>












    </mat-tab>



    <mat-tab label="  Job Work Note">


      <mat-accordion>


        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Job Work Note
            </mat-panel-title>

          </mat-expansion-panel-header>


          <form [formGroup]="add_job_work_note_form" (ngSubmit)="submitJobWorkNote()">



            <div class="form-group">
              <label for="descriptions"> Job Work Note</label>
              <textarea rows="3" cols="4" formControlName="job_work_note" placeholder="Job work note"
                name="job_work_note" class="form-control" id="job_work_note"></textarea>
            </div>




            <div class="modal-footer">
              <button type="submit" class="btn btn-outline-primary btn-sm">Save</button>
            </div>



          </form>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>

            <mat-panel-title>
              Job Work Note List
            </mat-panel-title>

          </mat-expansion-panel-header>
          <div class="table-responsive">

            <table class="table table-striped">
              <thead>
                <tr>
                  <th> ID </th>
                  <th>Note</th>
                  <th>created_by</th>
                  <th>Date </th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let t of job_work_note_list index as i;">

                  <td>{{t.id}}</td>
                  <td>{{t.job_work_note}}</td>


                  <td>{{t.email}}</td>
                  <td>{{t.created_at}}</td>

                </tr>
              </tbody>
            </table>
          </div>


        </mat-expansion-panel>

      </mat-accordion>
    </mat-tab>
  </mat-tab-group>