



<div class="row">

<div class="col-md-3">
  <h1 class="card-title">Job Work Report Grid </h1>
 


</div>

<div class="col-md-9   text-right">



  <mat-form-field>
    <mat-label>Select Engineer </mat-label>
    <mat-select [(ngModel)]="selectedEngineer" name="food">

      <mat-option [value]="'All'">

        All
      </mat-option>


      <mat-option   *ngFor="let engineer  of engineers_list" [value]="engineer.name"  
      (click)="externalFilterChanged('engineer',engineer.name)">
        {{engineer.name}}
      </mat-option>

    
    </mat-select>
  </mat-form-field>
 
  
 




  <button class="btn btn-primary  btn-sm   " (click)="onBtnExport()">Download Data</button>

 
</div>
</div>
 

<!-- AG Grid Angular Component -->
<ag-grid-angular style="width: 100%; height: 100%" 
class="ag-theme-alpine" 
[columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef" 
  [rowData]="job_work_report " 
  [animateRows]="true"
   [pagination]="true"

   [isExternalFilterPresent]="isExternalFilterPresent"
   [doesExternalFilterPass]="doesExternalFilterPass"


  [paginationAutoPageSize]="true" 
  [rowSelection]="rowSelection"
  (selectionChanged)="onSelectionChanged()"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

