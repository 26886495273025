

<div class="site">
 

<div class="row">
  <div class="col-md-12">
     <form [formGroup]="addForm" (ngSubmit)="onSubmit()"> 
     	  <h3>Add Service Problem</h3>


<div class="form-group">
      <label for="title"> Title</label>
      <input formControlName="title"  placeholder="Title" name="title" class="form-control" id="title" >
    </div>
       

    <div class="form-group">
      <label for="problem_details">Problem Details</label>
      <textarea rows="3" cols="7"   formControlName="problem_details"  placeholder="Problem Details..." name="problem_details" class="form-control" id="problem_details" ></textarea>
    </div>


<div class="form-group">
      <label for="image">Upload Image</label>
      <input type="file"   formControlName="image"  placeholder="Upload Image" name="image" class="form-control" id="image" >
    </div>

  <div class="modal-footer">
    <button  type="submit" class="btn btn-outline-primary btn-sm" >Submit</button>

  </div>

  </form>
</div></div></div>