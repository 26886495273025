<div class="site">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="addForm" (ngSubmit)="submit()">
        <div class="step1" *ngIf="step1">

          <h3> Step 1 </h3>


          <h3>Add Customer</h3>
          <div class="form-group">
            <label for="name">Name</label>
            <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name"
              class="form-control" id="name">
          </div>

          <div class="form-group">
            <label for="mobile_number">Mobile Number</label>
            <input formControlName="mobile_number"  required minlength="10" maxlength="10"    placeholder="Mobile Number" name="mobile_number"
              class="form-control" id="mobile_number">
          </div>

          <div class="form-group">
            <label for="alternative_mobile_number">Alternative Mobile Number</label>
            <input formControlName="alternative_mobile_number" placeholder="Mobile Number"
              name="alternative_mobile_number" class="form-control" minlength="10" maxlength="10" id="alternative_mobile_number">
          </div>



          <div class="modal-footer">
            <button type="button" (click)="onSubmit1()" class="btn btn-outline-primary btn-sm">Next</button>

          </div>


        </div>

        <div class="step2" *ngIf="step2">
          <h3> Step 2 </h3>

               <h3>Add Product</h3>

<div class="form-group">
      <label for="name">Name</label>
      <input rows="3" cols="4"  formControlName="product_name"  placeholder="Name" name="product_name" class="form-control" id="product_name" >
    </div>

<div class="form-group">
      <label for="original_price">Original Price</label>
      <input formControlName="original_price" placeholder="Original Price" name="original_price" class="form-control" id="original_price" >
    </div>

<div class="form-group">
      <label for="sell_price">Sell Price</label>
      <input formControlName="sell_price" placeholder="Sell Price" name="sell_price" class="form-control" id="sell_price" >
    </div>


<div class="form-group">
      <label for="discount">Discount</label>
      <input formControlName="discount" placeholder="Discount" name="discount" class="form-control" id="discount" >
    </div>


<div class="form-group">
      <label for="wholesale_price">WholeSale Price</label>
      <input formControlName="wholesale_price" placeholder="WholeSale Price" name="wholesale_price" class="form-control" id="wholesale_price" >
    </div>


<div class="form-group">
      <label for="discount">WholeSale Minimum Quantity</label>
      <input type="number" formControlName="wholesale_min_quantity" placeholder="WholeSale Minimum Quantity" name="wholesale_min_quantity" class="form-control" id="wholesale_min_quantity" >
    </div>
    

<div class="form-group">
      <label for="amount">Category</label>
      <select name="cate_id" id="cate_id" class="form-control" formControlName="cate_id">
  <option *ngFor="let t of category_list index as i;" value="{{t.id}}" >{{t.name}}</option>

</select>
     
    </div>


<div class="form-group">
      <label for="amount">Subcategory</label>
      <select name="sub_cate_id" id="sub_cate_id" class="form-control" formControlName="sub_cate_id">
  <option *ngFor="let t of subcategory_list index as i;" value="{{t.id}}" >{{t.name}}</option>

</select>
     
    </div>


<div class="form-group">
      <label for="amount">Brand</label>
      <select name="brand_id" id="brand_id" class="form-control" formControlName="brand_id">
  <option *ngFor="let t of brand_list index as i;" value="{{t.id}}" >{{t.name}}</option>

</select>
     
    </div>


   <div class="form-group">
      <label for="descriptions">Description</label>
      <textarea rows="3" cols="4" formControlName="descriptions"  placeholder="Enter Description..." name="descriptions" class="form-control" id="descriptions" ></textarea>
    </div>

       
<div class="form-group">
      <label for="cover">Upload Image</label>
      <ngx-dropzone (change)="onSelect($event,'cover_image')">
    <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"  [removable]="true" (removed)="onRemove(f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</ngx-dropzone>
    </div>
       


          <div class="modal-footer">
            <button (click)="onSubmit2()" class="btn btn-outline-primary btn-sm">Next</button>

          </div>


        </div>


        <div class="step3" *ngIf="step3">
          <h3> Step 3 </h3>


          <h3>Add Address</h3>



          <div class="form-group">
            <label for="city">City</label>
            <input rows="3" cols="4" formControlName="city" placeholder="City" name="city" class="form-control"
              id="city">
          </div>

          <div class="form-group">
            <label for="pincode">Pincode</label>
            <input rows="3" cols="4" formControlName="pincode" placeholder="Pincode" minlength="6" maxlength="6"   name="pincode" class="form-control"
              id="pincode">
          </div>


          <div class="form-group">
            <label for="address">Address</label>
            <textarea rows="3" cols="4" formControlName="address" placeholder="Enter Address..." name="address"
              class="form-control" id="address"></textarea>
          </div>


          <div class="modal-footer">
            <button (click)="onSubmit3()" class="btn btn-outline-primary btn-sm">Next</button>

          </div>


        </div>


        <div class="step4" *ngIf="step4">
          <h3> Step 4 </h3>
          <h3>Payment Methods</h3>

 <div class="form-group">
 <input type="radio" formControlName="payment_method"  name="payment_method" value="Cash On Delivery">&nbsp;
<label for="html"><h2>Cash On Delivery</h2></label><br>
<input type="radio" formControlName="payment_method" name="payment_method" value="Check Payments">&nbsp;
<label for="css"><h2>Check Payments</h2></label><br>
<input type="radio" formControlName="payment_method" name="payment_method" value="Direct Bank Transfer">&nbsp;
<label for="javascript"><h2>Direct Bank Transfer</h2></label> <br>
<input type="radio"formControlName="payment_method"  name="payment_method" value="PAYTM">&nbsp;
<label for="javascript"><h2>PAYTM</h2></label> 
</div>


          <div class="modal-footer">
            <button (click)="onSubmit4()" type="button" class="btn btn-outline-primary btn-sm">Next</button>

          </div>


        </div>


        <div class="step5" *ngIf="step5">
          <h3> Step 5 </h3>
           <mat-card class="example-card">
            <mat-card-title> {{product_name}}</mat-card-title><br>

          </mat-card>
          <br>
         


            <mat-card class="example-card">
              <mat-card-title> Description</mat-card-title><br>

              <mat-card-content>
                <p class="text-start mx-auto"> {{description}}</p>




              </mat-card-content>

            </mat-card>

<br>
            <mat-card class="example-card">
              <mat-card-title>Address</mat-card-title><br>

              <mat-card-content>
                <p>Mobile Number : {{customer_mobile_number}}</p>
                <p>City : {{city}}</p>
                <p>Pincode: {{pincode}}</p>
                <p>Address Line 1 : {{address}}</p>
                <p>Address Line 2: {{address}}</p>

              </mat-card-content>

            </mat-card>

<br>
            <mat-card class="example-card">
              <mat-card-title>Product Details</mat-card-title><br>

              <mat-card-content>
                <mat-card-title>{{product_name}}</mat-card-title>
                <mat-card-subtitle>${{sell_price}}</mat-card-subtitle>
                <mat-card-content>
                <figure class="figure" style="padding:30px;">
  <img src="{{cover}}" class="img-thumbnail"  width="200px" height="200px">

</figure>
                </mat-card-content>

              </mat-card-content>
           
            </mat-card>

<br>
            <mat-card class="example-card">
              <mat-card-title>Payment</mat-card-title><br>

              <mat-card-content>
                <mat-card-title>{{payment_method}}</mat-card-title>
                <mat-card-subtitle>${{sell_price}}</mat-card-subtitle>
                <mat-card-content>
                
                </mat-card-content>

              </mat-card-content>
              <mat-card-actions>
                <button class="btn btn-primary btn-sm" type="submit">Confirm and Submit</button>


              </mat-card-actions>
            </mat-card>



        </div>

      </form>
    </div>
  </div>
</div>