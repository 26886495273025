 



                    <h1 class="card-title">Job work report </h1>

                    <div class="table-responsive">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Job Work ID </th>
                                    <th>Title</th>
                                    <th>Customer name </th>
                                   
                                    

                                    <th> Estimate Total </th>
                                    <th>Status </th>

                                    <th>Date </th>

                                </tr>

                            </thead>
                            <tbody>
                                <tr *ngFor="let t of engineer_jobs index as i;">

                                    <td> {{t.job_work_id}}</td>
                                    <td>{{t.job_work_title}}</td>



                                    <td>{{t.customer_name }} </td>

 

  



                                    <td> {{t.estimate_total}} </td>
                                    <td>{{t.job_work_status}}</td>

                                    <td>{{t.job_work_created_at}}</td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
 