<div *ngIf="show">

    <div class="row">


        
        <div class="col-md-4">

    <h1 class="card-title">Job report </h1>
</div>


        <div class="col-md-4">



            <form class="form-inline" [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="form-group mx-sm-3 mb-2">
                    <input type="date" class="form-control" id="date_search" placeholder="Date"
                        formControlName="date_search">
                </div>
                <button type="submit" class="btn btn-primary btn-sm ">Search</button>

            </form>



        </div>
 

        <div class="col-md-4">
            <form class="form-inline" [formGroup]="jobsearchForm" (ngSubmit)="jobsearch()">
                <div class="form-group mx-sm-3 mb-2">
                    <input type="text" width=10 class="form-control" id="job_work_id" placeholder="Job Work Id  "
                        formControlName="job_work_id">
                </div>
                <button type="submit" class="btn btn-primary btn-sm ">Search Job work</button>

            </form>
        </div>



    </div>

 








    <mat-tab-group>
        <mat-tab label="Open">
            <div class="table-responsive">

                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Job  ID </th>
                            <th>Title</th>
                            <th>Customer  </th>
                            <th>Mobile  </th>


                            <th>Remark</th>
                            <th> Machine </th>
                            <th> Brand </th>
                            <th> Model </th>


                            <th> Estimate  </th>
                           
                            <th>Engineer  </th>

                            <th>Date </th>

                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let t of job_work_report index as i;" (click)="sendto(t.job_work_id)">

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'"> {{t.job_work_id}}</td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.job_work_title}}</td>



                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.name }} {{t.email }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'"> {{t.mobile_number }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.job_work_note }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.machine_title }} </td>

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'"> {{t.brand }} </td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.model }} </td>





                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'"> {{t.amount +t.service_charge | number}} </td>
                      
                     
                     
                          
                                
                                
                                <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.engineer_name}}</td>

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Open'">{{t.job_work_created_at}}</td>


                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>



        <mat-tab label="Hold">
            <div class="table-responsive">

                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>


                            <th>Job ID </th>
                            <th>Title</th>
                            <th>Customer  </th>
                            <th> Mobile  </th>
                            <th>Remark</th>
                            <th> Machine </th>
                            <th> Brand </th>
                            <th> Model </th>


                            <th> Estimate  </th>
                            
                            <th>Engineer  </th>
                            <th>Date </th>



                        </tr>




                    </thead>
                    <tbody>
                        <tr *ngFor="let t of job_work_report index as i;" (click)="sendto(t.job_work_id)">

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Hold'"> {{t.job_work_id}}</td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Hold'">{{t.job_work_title}}</td>



                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Hold'">{{t.name }}  {{t.email }}   </td>


                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Hold'"> {{t.mobile_number }} </td>


                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"   *ngIf="t.job_work_status     ==   'Hold'">{{t.job_work_note }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"   *ngIf="t.job_work_status     ==   'Hold'">{{t.machine_title }} </td>

                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"   *ngIf="t.job_work_status     ==   'Hold'"> {{t.brand }} </td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.job_work_status     ==   'Hold'">{{t.model }} </td>




                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"   *ngIf="t.job_work_status     ==   'Hold'"> {{t.amount +t.service_charge | number}} </td>
                        

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"   *ngIf="t.job_work_status     ==   'Hold'">{{t.engineer_name}}</td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"   *ngIf="t.job_work_status     ==   'Hold'">{{t.job_work_created_at}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>


        <mat-tab label="Closed">
            <div class="table-responsive">

                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>


                            <th>Job ID </th>
                            <th>Title</th>
                            <th>Customer  </th>
                            <th> Mobile  </th>
                            <th>Remark</th>
                            <th> Machine </th>
                            <th> Brand </th>
                            <th> Model </th>
                            <th> Estimate  </th>
                            <th> Status  </th>
                         
                       


                            <th>Engineer  </th>
                            <th>Date </th>



                        </tr>




                    </thead>
                    <tbody>
                        <tr *ngFor="let t of job_work_report index as i;" (click)="sendto(t.job_work_id)">

              <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.job_work_id}}</td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.job_work_title}}</td>



                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.name }} {{t.email }}  </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.mobile_number }} </td>


            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
            (  t.deposit_machine_status     !=   'Delivered') ">{{t.job_work_note }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.machine_title }} </td>

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')  ">
                                {{t.brand }} </td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.model }} </td>



                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')   ">
                                {{t.amount +t.service_charge | number}} </td>
                          

               

   <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
   *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                     (  t.deposit_machine_status     !=   'Delivered')  ">
                       {{t.job_work_status}}</td>



   <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
            *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                              (  t.deposit_machine_status     !=   'Delivered')  ">
                                {{t.engineer_name}}</td>




                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" 
                                *ngIf="(t.job_work_status     ==   'Closed'   || t.job_work_status   ==   'Incomplete_closed') && 
                                (  t.deposit_machine_status     !=   'Delivered')  ">
                                {{t.job_work_created_at}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>




        <mat-tab label="Delivered">
            <div class="table-responsive">

                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>


                            <th>Job ID </th>
                            <th>Title</th>
                            <th>Customer  </th>
                            <th> Mobile </th>
                            <th>Remark</th>
                            <th> Machine </th>
                            <th> Brand </th>
                            <th> Model </th>


                            <th> Estimate  </th>
                            <th>Status </th>
                        
                            <th>Engineer  </th>
                            <th>Date </th>



                        </tr>




                    </thead>
                    <tbody>
                        <tr *ngFor="let t of job_work_report index as i;" (click)="sendto(t.job_work_id)">

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered'  "> {{t.job_work_id}}</td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.job_work_title}}</td>



                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.name }} {{t.email }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' "> {{t.mobile_number }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.job_work_note }} </td>


                            <td [ngClass]="{'table-warning': t.created_id !==  159 }" *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.machine_title }} </td>

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' "> {{t.brand }} </td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.model }} </td>




                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' "> {{t.amount +t.service_charge | number}} </td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.job_work_status}}</td>     
                            
       
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered' ">{{t.engineer_name}}</td>
                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  *ngIf="t.deposit_machine_status     ==   'Delivered'   || t.Status   ==   'Delivered'  ">{{t.job_work_created_at}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>


        <mat-tab label="All">
            <div class="table-responsive">

                <table class="table table-striped table-bordered table-sm">
                    <thead>
                        <tr>


                            <th>Job ID </th>
                            <th>Title</th>
                            <th>Customer  </th>
                            <th> Mobile   </th>
                            <th>Remark</th>
                            <th> Machine </th>
                            <th> Brand </th>
                            <th> Model </th>
                            <th> Estimate  </th>
                            <th>Status </th> 
                              <th>Delivery Status </th>
                            <th>Engineer  </th>
                            <th>Date </th>



                        </tr>




                    </thead>
                    <tbody>
                        <tr *ngFor="let t of job_work_report index as i;" (click)="sendto(t.job_work_id)">

                            <td [ngClass]="{'table-warning': t.created_id !==  159 }"  >  {{t.job_work_id}}</td>
                            <td   [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.job_work_title}}</td>



                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.name }}  {{t.email }} </td>


                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  > {{t.mobile_number }} </td>


                            <td   [ngClass]="{'table-warning': t.created_id !==  159 }"  >  {{t.job_work_note }} </td>



                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.machine_title }} </td>

                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  > {{t.brand }} </td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.model }} </td>



                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  > {{t.amount +t.service_charge  | number}} </td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.job_work_status}}</td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"   >    <span *ngIf="t.deposit_machine_status     ==   'Delivered'" >
                                {{t.deposit_machine_status}}.
                         </span> </td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.engineer_name}}</td>
                            <td  [ngClass]="{'table-warning': t.created_id !==  159 }"  >{{t.job_work_created_at}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-tab>







    </mat-tab-group>

</div>