

<div class="site">
 


 <div class="row">
  <div class="col-md-6">
<form class="form-horizontal">
<fieldset>
<legend>  Payment</legend>
<br>
<mat-card>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Payment success</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Payment benchmark</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"> Random number (0-9)
 Random Letter (A-Z)</span>  
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Payment need to be approved</label>  
 <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

</mat-card>

<br>
<mat-card>
<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Activate credit card</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>
<hr>
<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Erase the private key</label>
  <div class="col-md-4">
    <input id="passwordinput" name="passwordinput" type="password" placeholder="" class="form-control input-md">
    <span class="help-block">
Stripe dashboard > API > Secret key (click on reveal key token) </span>
  </div>
</div>
</mat-card>
<!-- Password input-->
<br>
<mat-card>
<div class="form-group">
  <label class="col-md-4 control-label" for="">Enable Paypal</label>
 <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>
<hr>
<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Enable Paypal Live mode</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 
    
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Paypal customer ID</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
<hr>
<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Paypal Customer Secret</label>
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
</mat-card>
<br>
<mat-card>
<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Activate Fortumo</label>
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
<hr>
<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="">Fortumo Secret Key</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>
<hr>
<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="">Fortumo service key</label>
  <div class="col-md-4">
    <input id="" name="" type="" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>
<hr>
<!-- Select Basic -->
<div class="form-group">
  <label class="col-md-4 control-label" for="selectbasic">Fortumo payment rates (in%)</label>
  <div class="col-md-4">
    <select id="selectbasic" name="selectbasic" class="form-control">
      <option value="1">Option one</option>
      <option value="2">Option two</option>
    </select>
  </div>
</div>
</mat-card>
<br>

<mat-card>
<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="">CoinGate Authentication Token</label>
  <div class="col-md-4">
    <input  name="passwordinput" type="" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>
<hr>
<!-- Search input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="searchinput">CoinGate Receive Coin</label>
  <div class="col-md-4">
    <input id="searchinput" name="searchinput" type="search" placeholder="" class="form-control input-md">
    <p class="help-block"></p>
  </div>
</div>
<hr>
<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="">CoinGate Authentication Token</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>
<hr>
<!-- Select Basic -->
<div class="form-group">
  <label class="col-md-4 control-label" for="selectbasic">CoinGate Receive Coin</label>
  <div class="col-md-4">
    <select id="selectbasic" name="selectbasic" class="form-control">
      <option value="1">Option one</option>
      <option value="2">Option two</option>
    </select>
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">CoinGate Payment Fees (in%)</label>  
  <div class="col-md-4">
  <input id="" name="" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
</mat-card>
<br>

<mat-card>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Enable co-payments</label>
   <div class="col-md-4">
  <input  name="" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
<hr>
<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Coinpayments public key</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      1
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      2
    </label> 
  
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Coinpayments Private Key</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<hr>





<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Coinpayments Marchand ID</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">IPN Secret Coinpayments</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
<hr>
<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Co-payments payment rates (in%)</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
</mat-card>
<br>


<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Enable Rave's FlaveWave payment</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Rave Flutterwave sandbox mode</label>
 <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Public Key Flave Flutterwave</label>
  <div class="col-md-4">
    <input id="passwordinput" name="passwordinput" type="password" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Secret Key Flave Flutterwave</label>
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Rave Flutterwave payment title</label>  
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Payment Preface Rave Flutterwave</label>
  <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Payment rates Rave Flutterwave (%)</label>
   <div class="col-md-4">
  <input id="textinput" name="textinput" type="" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Enable Coinbase Commerce payment</label>
 <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Coinbase Commerce API Key</label>
  <div class="col-md-4">
    <input id="passwordinput" name="passwordinput" type="password" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Select Basic -->
<div class="form-group">
  <label class="col-md-4 control-label" for="selectbasic">Coinbase commerce Payment Title</label>
  <div class="col-md-4">
    <select id="selectbasic" name="selectbasic" class="form-control">
      <option value="1">Option one</option>
      <option value="2">Option two</option>
    </select>
  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Coinbase trade payment rates (in%)</label>
  <div class="col-md-4">
    <input id="" name="" type="" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Search input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="searchinput">Enable Mollie</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">Mollie Key</label>
  <div class="col-md-4">
    <input id="" name="" type="" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Select Basic -->
<div class="form-group">
  <label class="col-md-4 control-label" for="selectbasic">Mollie payment rates (in%)</label>
  <div class="col-md-4">
    <select id="selectbasic" name="selectbasic" class="form-control">
      <option value="1">Option one</option>
      <option value="2">Option two</option>
    </select>
  </div>
</div>

<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Enable Payeer</label>  
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Payeer Store ID</label>
  <div class="col-md-4">
  <input id="" name="" type="" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Payer API key</label>
  <div class="col-md-4">
  <input id="" name="" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="">Payer payment rates (in%)</label>  
  <div class="col-md-4">
  <input id="" name="" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>
















<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Enable Polipayments</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      1
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      2
    </label> 
  
  </div>
</div>

<!-- Text input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="textinput">Polipayments marchand code</label>  
  <div class="col-md-4">
  <input id="" name="" type="" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Polipayments authentication code</label>
  <div class="col-md-4">
  <input id="" name="" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Polipayments fees</label>
  <div class="col-md-4">
  <input id="" name="" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="">Enable PayStack</label>
   <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" >Paystack public key</label>
  <div class="col-md-4">
    <input id="" name=""  placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Select Basic -->
<div class="form-group">
  <label class="col-md-4 control-label" for="selectbasic">Paystack private key</label>
    <div class="col-md-4">
    <input id="" name="" type="" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" for="passwordinput">PayStack fees</label>
  <div class="col-md-4">
    <input id="" name="" type="" placeholder="" class="form-control input-md">
    <span class="help-block"></span>
  </div>
</div>

<!-- Search input-->
<div class="form-group">
  <label class="col-md-4 control-label">Enable transferred bank</label>
  <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Password input-->
<div class="form-group">
  <label class="col-md-4 control-label" >Maximum bank transfer at the same time</label>
   <div class="col-md-4">
    <select id="selectbasic" name="selectbasic" class="form-control">
      <option value="1">Option one</option>
      <option value="2">Option two</option>
    </select>
  </div>
</div>

<!-- Select Basic -->
<div class="form-group">
  <label class="col-md-4 control-label" for="selectbasic">Bank transfer proof activated</label>
 <div class="col-md-4"> 
    <label class="radio-inline" for="radios-0">
      <input type="radio" name="radios" id="radios-0" value="1" checked="checked">
      on
    </label> 
    <label class="radio-inline" for="radios-1">
      <input type="radio" name="radios" id="radios-1" value="2">
      off
    </label> 

  </div>
</div>

<!-- Text input-->


<!-- Multiple Radios (inline) -->
<div class="form-group">
  <label class="col-md-4 control-label" for="radios">Maximum proof of bank transfer</label>
   <div class="col-md-4">
    <select id="selectbasic" name="selectbasic" class="form-control">
      <option value="1">Option one</option>
      <option value="2">Option two</option>
    </select>
  </div>
</div>

<!-- Multiple Radios (inline) -->
<div class="form-group">

 
  <label class="col-md-4 control-label" for="selectbasic">Bank payment fees (in%)</label>
   <div class="col-md-4">
  <input id="textinput" name="textinput" type="text" placeholder="" class="form-control input-md">
  <span class="help-block"></span>  
  </div>
</div>


<!-- Text input-->


</fieldset>
</form>

</div></div>
</div>


