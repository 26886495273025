<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Coupan</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="addForm" (ngSubmit)="submit()">
      <div>
        <h3>Add Coupan</h3>

        <div class="form-group">
          <label for="coupan_code">Coupan Code</label>
          <input rows="3" cols="4" formControlName="coupan_code" placeholder="Coupan Code" name="coupan_code"
            class="form-control" id="coupan_code">
        </div>

        <div class="form-group">
          <label for="name">Discount %</label>
          <input rows="3" cols="4" formControlName="discount" placeholder="Discount" name="discount"
            class="form-control" id="discount">
        </div>






        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Coupan</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editForm" (ngSubmit)="updatesubmit()">
      <div>
        <h3>Edit Coupan</h3>

        <div class="form-group">
          <label for="coupan_code">Coupan Code</label>
          <input rows="3" cols="4" formControlName="coupan_code" placeholder="Coupan Code" name="coupan_code"
            class="form-control" id="coupan_code">
        </div>

        <div class="form-group">
          <label for="name">Discount</label>
          <input rows="3" cols="4" formControlName="discount" placeholder="Discount" name="discount"
            class="form-control" id="discount">
        </div>



        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>




<h1 class="card-title">Coupan List </h1>

<button (click)="add(content)" class="btn btn-primary btn-large">Add Coupan</button>


<div class="table-responsive">

  <table class="table table-striped">
    <thead>
      <tr>
        <th>ID </th>
        <th>Code</th>
        <th>Discount %</th>
        <th>Date </th>
        <th>Action </th>
        <th> </th>
      </tr>

    </thead>
    <tbody>
      <tr *ngFor="let t of coupan_list index as i;">

        <td> {{t.id}}</td>
        <td>{{t.coupan_code}}</td>
        <td>{{t.discount }} % </td>
        <td>{{t.created_at}}</td>
        <td> <button (click)="edit(content1,t.id)" class="btn btn-primary btn-sm">Edit</button></td>
        <td> <button (click)="delete(t.id)" class="btn btn-danger btn-sm">Delete</button></td>


      </tr>
    </tbody>
  </table>
</div>