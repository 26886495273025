import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Job } from '../models/job';



 
import { Observable } from 'rxjs';
import { Document } from "../models/document.model";


@Injectable({
  providedIn: 'root'
})
export class JobService {
  updatedocument(arg0: { document_type: any; document: any; id: number; }) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }




  baseUrl = 'https://nbsadmin23.nbscomputer.com/api/';

  //baseUrlauth = 'https://nbsadmin23.nbscomputer.com/api/auth';

  public print_job_work = 'https://nbsadmin23.nbscomputer.com/print_get_job_work';

 // bar_code_url = 'https://nbsadmin23.nbscomputer.com/print_get_job_work/';




  // private readonly baseurl = 'https://auth.lgbank.xyz/bitf/public/api/auth/';

  customer_details(mobile: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/customer_details' + '/' + mobile);
  }
  estimate_listby_customer(customer_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/estimate_listby_customer?customer_id=' + customer_id);
  }

  edit_engineer_details(engineer_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/edit_engineer_details?engineer_id=' + engineer_id);
  }
  message_listby_customer(customer_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/message_listby_customer?customer_id=' + customer_id);
  }

  notes_listby_customer(customer_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/notes_listby_customer?customer_id=' + customer_id);
  }


  servicelist_bycustomer(customer_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/servicelist_bycustomer?customer_id=' + customer_id);
  }



  orderby_customer(customer_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/orderby_customer?customer_id=' + customer_id);
  }

  referral_income_by_customer(customer_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/referral_income_by_customer?customer_id=' + customer_id);
  }


  changepassword(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/changepassword', user);
  }

  suspended_customer(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/system/suspended_customer');
  }

  machine_listby_customer(customer_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/machine_listby_customer?customer_id=' + customer_id);
  }


  delete_engineer(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/delete_engineer?id=' + id);
  }

  delete_customer(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/delete_customer?id=' + id);
  }


  engineer_job_list(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/engineer_job_list?id=' + id);
  }


  employee_work_summery(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminreport/employee_work_summery');
  }



  close_complain_status(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/close_complain_status?job_work_id=' + job_work_id);
  }
  inclose_complain_status(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/inclose_complain_status?job_work_id=' + job_work_id);
  }
  hold_complain_status(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/hold_complain_status?job_work_id=' + job_work_id);
  }

  deliver_machine_status(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/deliver_machine_status?job_work_id=' + job_work_id);
  }





  send_delivery_otp(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/send_delivery_otp?job_work_id=' + job_work_id);
  }
  verify_delivery_otp(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/verify_delivery_otp?job_work_id=' + job_work_id);
  }
  reopen_complain(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/reopen_complain?job_work_id=' + job_work_id);
  }



  delivery_machine_status(job_work_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/delivery_machine_status?job_work_id=' + job_work_id);
  }

  deposit_machine_status(job_work_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/deposit_machine_status?job_work_id=' + job_work_id);
  }
  //brand
  add_brand(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/brands/create', data);
  }

  update_brand(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/brands/update', data);
  }
  brand_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/brands/getAll');
  }

  delete_brand(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/brands/destroy?id=' + id);
  }

  edit_brand_details(brand_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/brands/getById?brand_id=' + brand_id);
  }


  //orderhistory1



  delete_order_product(product_id: number, order_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminecommerce/orders/delete_order_product?product_id=' + product_id + '&' + 'order_id=' + order_id);
  }


  edit_productbyid(order_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/edit_productbyid?order_id=' + order_id);
  }

  order_book(orderId: number, productId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/order_book?order_id=' + orderId + '&product_id=' + productId);
  }
  add_order_note(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/add_order_note', data);
  }

  update_orderproduct(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/update_orderproduct', data);
  }


  add_shipping_details(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/add_shipping_details', data);
  }

  new_customer_for_order(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/new_customer_for_order', data);
  }

  orders_history(search_date: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/orders_history/' + search_date);
  }

  order_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/getAll');
  }
  add_order(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/create', data);
  }

  get_order_details(order_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/get_order_details?order_id=' + order_id);
  }

  edit_order_details(order_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/getById?order_id=' + order_id);
  }



  order_status_update(order_id: number, status: any): Observable<any> {


    var order_query = {
      "order_id": order_id,
      "status": status
    };


    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/order_status_update', order_query);
  }



  update_order(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/update_order', data);
  }

  delete_productorder(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/orders/destroy?id=' + id);
  }


  order_seacrh_customer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/order_seacrh_customer', data);
  }

  customer_for_order(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/orders/customer_for_order', data);
  }

  //category
  add_category(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/categories/create', data);
  }

  update_category(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/categories/update', data);
  }
  category_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/categories/getAll');
  }

  delete_category(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/categories/destroy?id=' + id);
  }

  edit_category_details(category_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/categories/getById?category_id=' + category_id);
  }



  //subcategory
  add_subcategory(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/sub_cate/create', data);
  }

  update_subcategory(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/sub_cate/update', data);
  }
  subcategory_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/sub_cate/getAll');
  }

  delete_subcategory(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/sub_cate/destroy?id=' + id);
  }

  edit_subcategory_details(subcategory_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/sub_cate/getById?subcategory_id=' + subcategory_id);
  }

  //product
  add_product(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/products/create', data);
  }


  importsproductcsv(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/imports/productcsv', data);
  }







  update_product(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminecommerce/products/update', data);
  }
  product_list1(page: any): Observable<any> {

    return this.http.get<any>(this.baseUrl + 'auth/adminecommerce/products/getTopList?page=' + page);
  }
  product_list_all(): Observable<any> {

    return this.http.get<any>(this.baseUrl + 'auth/adminecommerce/products/getTopList');
  }

  product_list(): Observable<any> {

    return this.http.get<any>(this.baseUrl + 'auth/adminecommerce/products/getTopList');
  }




  unpublish_product(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/products/unpublish_product?id=' + id);
  }

  publish_product(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/products/publish_product?id=' + id);
  }
  
  delete_product(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/products/destroy?id=' + id);
  }

  edit_product_details(product_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/products/getProductById?product_id=' + product_id);
  }

  getallnotification(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/getallnotification');
  }
  //coupan
  coupan_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/coupan_list');
  }
  login_history(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/login_history');
  }
  customer_login_history(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/customer_login_history');
  }
  customer_browsing_history(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/customer_browsing_history');
  }
  active_customer_in_app(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminecommerce/active_customer_in_app');
  }

  


  delete_coupan(coupan_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/delete_coupan?coupan_id=' + coupan_id);
  }

  edit_coupan_details(coupan_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/edit_coupan_details?coupan_id=' + coupan_id);
  }

  add_coupan(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_coupan', data);
  }

  update_coupan(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/update_coupan', data);
  }


  ///NEw api
  add_engineer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_engineer', data);
  }

  update_engineer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/updated_engineer', data);
  }

  address_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/address_list');
  }

  engineer_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/engineer_list');
  }

  assign_engineer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/assign_engineer', data);
  }

  assign_engineer_list(engineer_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/assign_engineer_list?engineer_id=' + engineer_id);
  }

  seacrh_customer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/seacrh_customer', data);
  }


  search_customer_details(mobile: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/search_customer_details?customer_mobile_number=' + mobile);
  }


  add_machinestepform(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_machinestepform', data);
  }

  customer_for_complain(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/customer_for_complain', data);
  }






  job_work_report(search_date: any): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/job_work_report/' + search_date);
  }

  add_job_work_note(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_job_work_note', data);
  }
  add_job_work(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_job_work', data);
  }

  customer_pin_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/customer_pin_report');
  }




  customer_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/customer_report');
  }
  wholeseller_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/wholeseller_report');
  }
 
 

  estimate_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/estimate_report');
  }

  in_deposit_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/in_deposit_report');
  }

  part_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/part_report');
  }








  all_job_work_report(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/all_job_work_report');
  }


  partproduct_detailsbyid(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/part_product_detailsbyid?id=' + id);
  }
  
  
  
  get_job_work(job_work_id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/get_job_work?job_work_id=' + job_work_id);
  }

  part_product_list(id: number, customer_id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/part_product_list?id=' + id + '&customer_id=' + customer_id);
  }

  /*
  servicelist_bycustomer(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/servicelist_bycustomer?id=' + id);
  }

  */
  edit_addressbyid(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/edit_addressbyid?id=' + id);
  }
  delete_address(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/delete_address?id=' + id);
  }

  update_address(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/update_address', data);
  }
  delete_part_product(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/delete_part_product?id=' + id);
  }
  machine_bycustomer(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/machine_bycustomer?id=' + id);
  }
  address_bycustomer(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/address_bycustomer?id=' + id);
  }

  update_partproduct(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/productUpdate', data);
  }

  delete_machine(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/delete_machine?id=' + id);
  }

  edit_machine_byid(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/adminsystem/edit_machine_byid?id=' + id);
  }
  update_machine(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/update_machine', data);
  }

  add_part_product(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_part_product', data);
  }
  add_customer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_customer', data);
  }

  update_customer(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/update_customer', data);
  }




  add_address(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_address', data);
  }


  /*

  add_service(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_service', data);
  }

  */
  add_machine(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/add_machine', data);
  }


  ////



  
  
  customer_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/system/allcustomerlist');
  }
  allcancelbookinglist(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/system/allcancelbookinglist');
  }
  allrefundbookinglist(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/system/allrefundbookinglist');
  }
  upcoming_booking_list(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/system/allupcomiguardbookinglist');
  }

  kyc_user_details(id: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'auth/system/kyc_user_details?id=' + id);
  }

  rate(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/system/rate?id=' + id);
  }

  payment_user(user: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/system/payment_user', user);
  }

  delete_currency(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/system/currencydelete?id=' + id);
  }

  delete_order(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'deleteorder?id=' + id);
  }
 

   


  getuserById(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/user');
  }


  profile_enable(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/enableg2f');
  }


  authcode(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/enableg2f', data);
  }
 
 

  updateProfile(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/postprofile', data);
  }

  
  getlogo(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.baseUrl + 'getlogo');
  }




  postlogo(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/' + 'postlogo', data);
  }



  changeStatus(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/' + 'changeStatus?id=' + id);

  }

  deleteaccountstatus(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/' + 'deleteaccount?id=' + id);

  }


  deleteplan(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/' + 'deleteplan?id=' + id);

  }

  accountStatus(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/' + 'accountStatus?id=' + id);

  }


  notificationfundStatus(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/' + 'notificationfundStatus');

  }
 
  set_up_down(set_up_down): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'auth/adminsystem/set_up_down',set_up_down);

  }
 
  
  upline_histories( ): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'auth/adminsystem/upline_histories' );

  }
  
  get_system_status( ): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'get_system_status' );

  }
   

  
}
