<h1>


  {{app_status}}  [ {{notification}}]
 
  

</h1>

<form class="form-inline" [formGroup]="set_up_downForm" (ngSubmit)="set_up_down()">






  <div class="form-group mx-sm-3 mb-2">
    <input type="text" class="form-control" id="mobile_number" placeholder="Customer  notification "
      formControlName="notification">
  </div>
  <button type="submit" class="btn btn-primary ">Set Offline </button>




</form>



<form class="form-inline" [formGroup]="set_up_downForm" (ngSubmit)="set_online()">






  <div class="form-group mx-sm-3 mb-2">
    <input type="text" class="form-control" placeholder="Customer  notification " formControlName="notification">
  </div>


  <button type="submit" class="btn btn-primary ">Set online </button>




</form>


<hr />

<div class="table-responsive">

  <table class="table table-striped table-bordered table-sm">
    <thead>
      <tr>


        <th> Message </th>
        <th>Status</th>

        <th>Created at </th>




      </tr>




    </thead>
    <tbody>
      <tr *ngFor="let t of histories;">

        <td> {{t.notification}}</td>
        <td> {{t.app_status}}</td>

        <td> {{t.created_at}}</td>
      </tr>
    </tbody>
  </table>
</div>