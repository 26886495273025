<form [formGroup]="addForm" (ngSubmit)="submit()">
  <div>

    <h3>Import Products CSV Sheet</h3>

    <a href="https://nbsbetaapi.sakshstore.com/index.php/live_products/" target="_blank">Download live products
      sheet.</a>






    <div class="form-group">
      <label for="cover">Upload Image</label>
      <ngx-dropzone (change)="onSelect($event,'csv_file')">
        <ngx-dropzone-label>Drop the Product CSV File here!</ngx-dropzone-label>

        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
          [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </ngx-dropzone>
    </div>



    <div class="modal-footer">
      <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>
    </div>


  </div>
</form>