<form *ngIf="step1" class="form-inline" [formGroup]="searchForm" (ngSubmit)="search()">






  <div class="form-group mx-sm-3 mb-2">
    <input type="text" width=10 class="form-control" id="mobile_number" placeholder="Customer Mobile Number"
      formControlName="customer_mobile_number">
  </div>
  <button type="submit" class="btn btn-secondary ">Search Customer</button>


  <button type="button" (click)="add_customer()" class="btn btn-secondary ">Add Order work for new a
    customer</button>
</form>


<form *ngIf="step1" class="form-inline" [formGroup]="ordersearchForm" (ngSubmit)="ordersearch()">
  <div class="form-group mx-sm-3 mb-2">
    <input type="text" width=10 class="form-control" id="order_id" placeholder="Order Id  " formControlName="order_id">
  </div>
  <button type="submit" class="btn btn-secondary ">Search Order Id</button>

</form>


<form [formGroup]="addForm" (ngSubmit)="submit()">



  <div class="step1" *ngIf="step1">



    <div *ngIf="show">


      <h3> Step 1 </h3>



      <button type="button" (click)="onSubmit1()" class="btn btn-primary btn-sm">Add order for this customer

      </button>


      <mat-accordion>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>

            <mat-panel-title>
              Customer Details
            </mat-panel-title>

          </mat-expansion-panel-header>

          <mat-card class="example-card">
            <mat-card-title>Customer Details</mat-card-title>


            <mat-card-content>


              <p>Name : {{customer_details.name}}</p>
              <p>Mobile Number : {{customer_details.mobile_number}}</p>
            </mat-card-content>
          </mat-card>


          <div class="modal-footer">
            <button type="button" (click)="onSubmit1()" class="btn btn-primary  ">Next</button>

          </div>


        </mat-expansion-panel>






        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>

            <mat-panel-title>
              Address Details
            </mat-panel-title>

          </mat-expansion-panel-header>




          <div class="row">


            <div class="col-md-4  " *ngFor="let add of address_list index as i;">
              <mat-card class="  example-card">

                <mat-card-content>
                  <p>Mobile Number : {{add.mobile_number}}</p>
                  <p>City : {{add.city}}</p>
                  <p>Pincode: {{add.pincode}}</p>
                  <p>Address Line 1 : {{add.address}}</p>
                  <p>Address Line 2: {{add.address}}</p>

                </mat-card-content>

              </mat-card>

            </div>
          </div>


        </mat-expansion-panel>






        <!-- 
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>

                  <mat-panel-title>
                    Machine Details
                  </mat-panel-title>

                </mat-expansion-panel-header>





                <div class="row">
                  <div class="col-md-4" *ngFor="let m of machine_list index as i;">
                    <mat-card class="example-card">
                      <mat-card-title>{{m.title}}</mat-card-title>

                      <mat-card-content>

<figure class="figure" style="padding:30px;">
  <img src="{{m.image}}" class="img-thumbnail"  width="200px" height="200px">

</figure>

                     
                        <br />
                        Brand {{m.brand}} <br /> Model {{m.model}}  


                      </mat-card-content>

                    </mat-card>
                  </div>


                </div>

              </mat-expansion-panel> 
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>

                  <mat-panel-title>
                    Job work List List
                  </mat-panel-title>

                </mat-expansion-panel-header>




                <div class="table-responsive">

                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Job Work ID </th>
                        <th>Title</th>

                        <th> Machine </th>
                        <th> Estimate Total </th>
                        <th>Parts details </th>
                        <th>Date </th>



                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let t of job_work_list index as i;">

                        <td>{{t.id}}</td>
                        <td>{{t.title}}</td>

                        <td></td>
                        <td></td>
                        <td></td>


                        <td>{{t.created_at}}</td>



                      </tr>
                    </tbody>
                  </table>
                </div>


              </mat-expansion-panel>


              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>

                  <mat-panel-title>
                    Estimate List
                  </mat-panel-title>

                </mat-expansion-panel-header>




                <div class="table-responsive">

                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Estimate ID</th>
                        <th>Job Work Id</th>

                        <th>Amount</th>
                        <th>Date </th>
                        <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let t of estimate_list index as i;">
                        <td>{{t.estimate_id}}</td>
                        <td>{{t.job_work_id}}</td>

                        <td>{{t.estimate_total}}</td>
                        <td>{{t.created_at}}</td>
                        <td>{{t.estimate_status}}</td>






                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel>




              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>

                  <mat-panel-title>
                    Notes List
                  </mat-panel-title>

                </mat-expansion-panel-header>



                <div class="table-responsive">

                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Job Work Id</th>

                        <th>Note</th>
                        <th>Date </th>
                        <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let t of notes_list index as i;">
                        <td>{{t.id}}</td>
                        <td>{{t.job_work_id}}</td>

                        <td>{{t.notes}}</td>
                        <td>{{t.created_at}}</td>
                        <td>{{t.status}}</td>






                      </tr>
                    </tbody>
                  </table>
                </div>
              </mat-expansion-panel> -->




      </mat-accordion>


    </div>
  </div>













  <div class="step2" *ngIf="step2">
    <h3> Step 2 </h3>
    <div class="float-right" *ngIf="!machinesteps">
      <mat-icon (click)="add_machine()">add_box</mat-icon>
    </div>

    <div class="row" *ngIf="!machinesteps">

      <div class="col-md-6" *ngFor="let m of product_list index as i;">



        <mat-card class="example-card">
          <mat-card-title>{{m.name}}</mat-card-title>
          <mat-card-subtitle>${{m.sell_price}}</mat-card-subtitle>
          <mat-card-content>

            <figure class="figure" style="padding:30px;">
              <img src="{{m.cover}}" class="img-thumbnail" width="200px" height="200px">

            </figure>
            <!-- <img src="{{t.image}}" /> -->
          </mat-card-content>
          <mat-card-actions>
            <button (click)="onproductSubmit2(m)" class="btn btn-outline-primary btn-sm">Select</button>

          </mat-card-actions>
        </mat-card>
      </div>



    </div>


    <mat-card class="example-card" *ngIf="machinesteps">
      <h3>Add Product</h3>

      <div class="form-group">
        <label for="name">Name</label>
        <input rows="3" cols="4" formControlName="product_name" placeholder="Name" name="product_name"
          class="form-control" id="product_name">
      </div>

      <div class="form-group">
        <label for="original_price">Original Price</label>
        <input formControlName="original_price" placeholder="Original Price" name="original_price" class="form-control"
          id="original_price">
      </div>

      <div class="form-group">
        <label for="sell_price">Sell Price</label>
        <input formControlName="sell_price" placeholder="Sell Price" name="sell_price" class="form-control"
          id="sell_price">
      </div>


      <div class="form-group">
        <label for="discount">Discount</label>
        <input formControlName="discount" placeholder="Discount" name="discount" class="form-control" id="discount">
      </div>


      <div class="form-group">
        <label for="wholesale_price">WholeSale Price</label>
        <input formControlName="wholesale_price" placeholder="WholeSale Price" name="wholesale_price"
          class="form-control" id="wholesale_price">
      </div>


      <div class="form-group">
        <label for="discount">WholeSale Minimum Quantity</label>
        <input type="number" formControlName="wholesale_min_quantity" placeholder="WholeSale Minimum Quantity"
          name="wholesale_min_quantity" class="form-control" id="wholesale_min_quantity">
      </div>


      <div class="form-group">
        <label for="amount">Category</label>
        <select name="cate_id" id="cate_id" class="form-control" formControlName="cate_id">
          <option *ngFor="let t of category_list index as i;" value="{{t.id}}">{{t.name}}</option>

        </select>

      </div>


      <div class="form-group">
        <label for="amount">Subcategory</label>
        <select name="sub_cate_id" id="sub_cate_id" class="form-control" formControlName="sub_cate_id">
          <option *ngFor="let t of subcategory_list index as i;" value="{{t.id}}">{{t.name}}</option>

        </select>

      </div>


      <div class="form-group">
        <label for="amount">Brand</label>
        <select name="brand_id" id="brand_id" class="form-control" formControlName="brand_id">
          <option *ngFor="let t of brand_list index as i;" value="{{t.id}}">{{t.name}}</option>

        </select>

      </div>


      <div class="form-group">
        <label for="descriptions">Description</label>
        <textarea rows="3" cols="4" formControlName="descriptions" placeholder="Enter Description..."
          name="descriptions" class="form-control" id="descriptions"></textarea>
      </div>


      <div class="form-group">
        <label for="cover">Upload Image</label>
        <ngx-dropzone (change)="onSelect($event,'cover_image')">
          <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
            [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-image-preview>
        </ngx-dropzone>
      </div>

      <div class="modal-footer">
        <button (click)="onSubmit2()" class="btn btn-outline-primary btn-sm">Next</button>
      </div>
    </mat-card>

  </div>

















  <div class="step3" *ngIf="step3">
    <h3> Step 3 </h3>

    <div class="float-right" *ngIf="!addresssteps">
      <mat-icon (click)="add_address()">add_box</mat-icon>

    </div>


    <div class="row" *ngIf="!addresssteps">

      <div class="col-md-4" *ngFor="let add of address_list index as i;">

        <mat-card class="example-card">

          <mat-card-content>
            <p>Mobile Number : {{add.mobile_number}}</p>
            <p>City : {{add.city}}</p>
            <p>Pincode: {{add.pincode}}</p>
            <p>Address Line 1 : {{add.address}}</p>
            <p>Address Line 2: {{add.address}}</p>

          </mat-card-content>
          <mat-card-actions>

            <button (click)="onmachineSubmit3(add)" class="btn btn-outline-primary btn-sm">Select</button>

          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <mat-card class="example-card" *ngIf="addresssteps">

      <h3>Add Address</h3>
      <div class="form-group">
        <label for="description">Name</label>
        <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
      </div>

      <div class="form-group">
        <label for="mobile_number">Mobile Number</label>
        <input rows="3" cols="4" formControlName="mobile_number" placeholder="Mobile Number" name="mobile_number"
          class="form-control" id="mobile_number">
      </div>


      <div class="form-group">
        <label for="city">City</label>
        <input rows="3" cols="4" formControlName="city" placeholder="City" name="city" class="form-control" id="city">
      </div>

      <div class="form-group">
        <label for="pincode">Pincode</label>
        <input rows="3" cols="4" formControlName="pincode" placeholder="Pincode" name="pincode" class="form-control"
          id="pincode">
      </div>


      <div class="form-group">
        <label for="address">Address</label>
        <textarea rows="3" cols="4" formControlName="address" placeholder="Enter Address..." name="address"
          class="form-control" id="address"></textarea>
      </div>


      <div class="modal-footer">
        <button (click)="onSubmit3()" class="btn btn-outline-primary btn-sm">Next</button>

      </div>
    </mat-card>

  </div>


  <div class="step4" *ngIf="step4">
    <h3> Step 4 </h3>
    <h3>Payment Methods</h3>

    <div class="form-group">
      <input type="radio" formControlName="payment_method" name="payment_method" value="Cash On Delivery">&nbsp;
      <label for="html">
        <h2>Cash On Delivery</h2>
      </label><br>
      <input type="radio" formControlName="payment_method" name="payment_method" value="Check Payments">&nbsp;
      <label for="css">
        <h2>Check Payments</h2>
      </label><br>
      <input type="radio" formControlName="payment_method" name="payment_method" value="Direct Bank Transfer">&nbsp;
      <label for="javascript">
        <h2>Direct Bank Transfer</h2>
      </label> <br>
      <input type="radio" formControlName="payment_method" name="payment_method" value="PAYTM">&nbsp;
      <label for="javascript">
        <h2>PAYTM</h2>
      </label>
    </div>

    <div class="modal-footer">
      <button (click)="onSubmit4()" type="button" class="btn btn-outline-primary btn-sm">Next</button>

    </div>


  </div>


  <div class="step5" *ngIf="step5">
    <h3> Step 5 </h3>
    <mat-card class="example-card">
      <mat-card-title> {{product_name}}</mat-card-title><br>

    </mat-card>
    <br>
    <span>


      <mat-card class="example-card">
        <mat-card-title> Description</mat-card-title><br>

        <mat-card-content>
          <p class="text-start mx-auto"> {{description}}</p>




        </mat-card-content>

      </mat-card>

      <br>
      <mat-card class="example-card">
        <mat-card-title>Address</mat-card-title><br>

        <mat-card-content>
          <p>Mobile Number : {{customer_mobile_number}}</p>
          <p>City : {{city}}</p>
          <p>Pincode: {{pincode}}</p>
          <p>Address Line 1 : {{address}}</p>
          <p>Address Line 2: {{address}}</p>

        </mat-card-content>

      </mat-card>

      <br>
      <mat-card class="example-card">
        <mat-card-title>Product Details</mat-card-title><br>

        <mat-card-content>
          <mat-card-title>{{product_name}}</mat-card-title>
          <mat-card-subtitle>${{sell_price}}</mat-card-subtitle>
          <mat-card-content>
            <figure class="figure" style="padding:30px;">
              <img src="{{cover}}" class="img-thumbnail" width="200px" height="200px">

            </figure>
          </mat-card-content>

        </mat-card-content>

      </mat-card>

      <br>
      <mat-card class="example-card">
        <mat-card-title>Payment</mat-card-title><br>

        <mat-card-content>
          <mat-card-title>{{payment_method}}</mat-card-title>
          <mat-card-subtitle>${{sell_price}}</mat-card-subtitle>
          <mat-card-content>

          </mat-card-content>

        </mat-card-content>
        <mat-card-actions>
          <button class="btn btn-primary btn-sm" type="submit">Confirm and Submit</button>


        </mat-card-actions>
      </mat-card>


    </span>


  </div>

</form>