

<div class="row">




   <div class="col-md-12">
    <div class="card" >


      <div class="card-body">  
  <h5 class="card-title">Payment</h5>
 
 Available Wallet Balance &#8383; {{balance }} 
 <br>

 User Details :
 <br>
 <table>
   <tr><td>Id : {{id}} ,  </td><td>Username: {{name}} ,  </td>
   <td>Email : {{email}} ,  </td><td>Balance : {{user_balance}} ,  </td></tr>
 </table>

<br>
  <form [formGroup]="addForm" (ngSubmit)="onSubmit()">
    
    <div class="form-group">
      <label for="amount">Amount</label>
      <input type="number" formControlName="amount" placeholder="Amount" name="amount" class="form-control" id="amount">
    </div>

    <!-- <div class="form-group">

        <label for="currency">Currency</label>

        <select name="coin" formControlName="coin" class="form-control">

                 <option *ngFor="let t of currency" value="{{t.currency}}">{{t.currency}}</option>


        </select>
      </div> -->
      
<div class="form-group">
      <label for="gender">Type</label>
      

      <select  class="form-control"  formControlName="type">

      



        <option  value="debit_user" >
         Debit
        </option> 

         <option  value="credit_user" >
        Credit
        </option>


      </select>


    </div>
 

    <button class="btn btn-primary btn-sm  ">Submit</button>



  </form>
  </div>

</div>
</div>


</div>