


export class img {



  name: string;
  url: string;
}
