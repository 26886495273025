<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add SubCategory</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="addForm" (ngSubmit)="submit()">
      <div>




        <h3>Add SubCategory</h3>

        <div class="form-group">
          <label for="amount">Category</label>
          <select name="cate_id" id="cate_id" class="form-control" formControlName="cate_id">
            <option *ngFor="let t of category_list index as i;" value="{{t.id}}">{{t.name}}</option>

          </select>

        </div>


        <div class="form-group">
          <label for="name">Name</label>
          <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
        </div>

        <div class="form-group">
          <label for="cover">Cover</label>
          <input formControlName="cover" placeholder="Cover" name="cover" class="form-control" id="cover">
        </div>




        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit SubCategory</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="editForm" (ngSubmit)="updatesubmit()">
      <div>
        <h3>Edit SubCategory</h3>

        <div class="form-group">
          <label for="amount">Category</label>
          <select name="cate_id" id="cate_id" class="form-control" formControlName="cate_id">
            <option *ngFor="let t of category_list index as i;" value="{{t.id}}">{{t.name}}</option>

          </select>

        </div>

        <div class="form-group">
          <label for="name">Name</label>
          <input rows="3" cols="4" formControlName="name" placeholder="Name" name="name" class="form-control" id="name">
        </div>

        <div class="form-group">
          <label for="cover">Cover</label>
          <input formControlName="cover" placeholder="Cover" name="cover" class="form-control" id="cover">
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-primary btn-sm">Submit</button>

        </div>


      </div>
    </form>
  </div>
</ng-template>



<div class="site">
  <div class="row">
    <div class="col-md-12">
      <h3>SubCategory List</h3><br>


      <button (click)="add(content)" class="btn btn-primary btn-large">Add SubCategory</button>


      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
            <tr>
              <th>  Id </th>
              <th>Name</th>
              <th>Cover</th>

            


              <th>Action </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tbody>
            <tr *ngFor="let t of subcategory_list index as i;">

              <td>{{t.id}}</td>
              <td>{{t.name}}</td>
              <td>{{t.cover}}</td>

 

              <td> <button (click)="edit(content1,t.id)" class="btn btn-primary btn-sm">Edit</button></td>
              <td> <button (click)="delete(t.id)" class="btn btn-danger btn-sm">Delete</button></td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>