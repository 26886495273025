


<div class="row">
  <div class="col-md-12">
    
      <div class="card"    >

  
  <div class="card-body"  >

  <legend>Set Logo</legend>
 
<ngx-dropzone (change)="onSelect($event,'logo')">
    <ngx-dropzone-label>Drop the logo here!</ngx-dropzone-label>

    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"  [removable]="true" (removed)="onRemove(f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>



</ngx-dropzone>


<div>

        <label>Your logo</label><br>
       

  </div></div></div>

</div></div>