<form *ngIf="showpage" [formGroup]="addForm" (ngSubmit)="submit()">

  <h1>
    {{customer_details.name}} {{customer_details.mobile_number}}

  </h1>

  <hr />

  <div class="row">




    <div class="col-md-12">

      <h3> Select / Add machine    </h3>


      <div class="row" *ngIf="!machinesteps">
        <div class="col-md-2  " *ngFor="let m of machine_list index as i;">



          <mat-card class="example-card " [ngStyle]="{'border':machine_id === m.id ? '5px solid green' : '' }"
            (click)="onmachineSubmit2(m)" id="{{m.id}}">
            <mat-card-title>{{m.title}}</mat-card-title>


            <mat-card-content>


              <figure class="figure">
                <picture> <img src="{{m.image}}" class="img-thumbnail rounded img-fluid"> </picture>

              </figure>
              Brand {{m.brand}} <br />
              Model {{m.model}}<br />
            </mat-card-content>

          </mat-card>
        </div>
        <div class="col-md-2">
          <mat-icon (click)="add_machine()">add_box</mat-icon>
        </div>


      </div>
    </div>



    <div class="col-md-12">
      <div class="example-card" *ngIf="machinesteps">
        <h3>Add Machine</h3>


        <div class="form-group">
          <label for="title">Title /Type </label>
          <input formControlName="machine_title" placeholder="Title" name="machine_title" class="form-control"
            id="machine_title">
        </div>


        <div class="form-group">
          <label for="brand">Brand</label>
          <input formControlName="brand" placeholder="Brand" name="brand" class="form-control" id="brand">
        </div>







        <div class="form-group">
          <label for="model">Model</label>
          <input formControlName="model" placeholder="Model" name="model" class="form-control" id="model">
        </div>


        <div class="form-group">
          <label for="machine_image">Upload Image</label>
          <ngx-dropzone (change)="onSelect($event,'machine_image')">
            <ngx-dropzone-label>Drop the image here!</ngx-dropzone-label>

            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>

        </div>



      </div>

    </div>


    <hr />



    <div class="col-md-12">




      <h3>Add Job Work details</h3>


      <div class="form-group">
        <label for="title"> Job work short Title</label>
        <input formControlName="title" placeholder="Title" name="title" class="form-control" id="title">
      </div>




      <div class="form-group">
        <label for="problem_details">Problem Details</label>
        <textarea rows="3" cols="7" formControlName="problem_details" placeholder="Problem Details..."
          name="problem_details" class="form-control" id="problem_details"></textarea>
      </div>







      <div class="form-group">
        <label for="title"> Service Charge</label>
        <input formControlName="service_charge" placeholder="service_charge" name="service_charge" class="form-control"
          id="service_charge">
      </div>


      <button [disabled]="isDisabled" class="btn btn-primary btn-sm" type="submit">Confirm and Generate bar
        code</button>
    </div>

  </div>





</form>