<h1 class="card-title">Engineer weekly report </h1>

<div class="table-responsive">

    <table class="table table-striped">
        <thead>
            <tr>


                <th>Engineer name </th>
                <th> week </th>
                <th> Estimate Total </th>


            </tr>

        </thead>
        <tbody>


            <tr *ngFor="let t of employee_work_report ">


                <td>{{t.engineer_name}}</td>



                <td> {{t.week}}</td>


                <td>{{t.total_amount}}</td>






            </tr>
        </tbody>
    </table>
</div>