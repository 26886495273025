

<div class="site">
   
  <div class="row fourwidget" >

   
<div class="col-md-3">

<div class="card">
  
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{total_users}} Users</p>
    
  </div>
</div>

</div><div class="col-md-3">


<div class="card  ">
  
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{active_users}}  Active Users </p>
   
  </div>
</div>

</div><div class="col-md-3">


<div class="card">
   
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{suspendedpartner}} Suspended Users</p>
   
  </div>
</div>

</div>
<div class="col-md-3">


<div class="card">
   
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{newregistered}} New Registered Users</p>
   
  </div>
</div>

</div>
</div> 

<br>
  <div class="row fourwidget" >

   
<div class="col-md-3">

<div class="card">
  
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{deleteaccount}} Remove Request Partner</p>
    
  </div>
</div>

</div><div class="col-md-3">


<div class="card  ">
  
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{customerlist}}  Customers </p>
   
  </div>
</div>

</div><div class="col-md-3">


<div class="card">
   
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{suspendedcustomer}} Suspended Customers</p>
   
  </div>
</div>

</div>
<div class="col-md-3">


<div class="card">
   
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{booking}} Booking</p>
   
  </div>
</div>

</div>
</div> 
<br>
  <div class="row fourwidget" >

   
<div class="col-md-3">

<div class="card">
  
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{upcomingbooking}} Upcoming Booking</p>
    
  </div>
</div>

</div><div class="col-md-3">


<div class="card  ">
  
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{pastbooking}}  Past Booking </p>
   
  </div>
</div>

</div><div class="col-md-3">


<div class="card">
   
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{cancelledbooking}} Cancelled Booking</p>
   
  </div>
</div>

</div>
<div class="col-md-3">


<div class="card">
   
  <div class="card-body">
 <div class="card-title float-right"></div>
    <p class="card-text">Total {{Refund_booking}} Refund Request</p>
   
  </div>
</div>

</div>
</div> 
</div>


